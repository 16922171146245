const AISToConning = (rot, heading, cog, sog, distanceToBow, distanceToStern) => {
  let radius_to_bow = distanceToBow
  let radius_to_stern = distanceToStern

  // console.log("To baw is it: " + distanceToBow);

  let course_diff = cog - heading
  let corr_deg = 0
  if (course_diff >= 0 && course_diff <= 90) {
    corr_deg = 90 - course_diff
  } else if (course_diff > 90 && course_diff <= 180) {
    corr_deg = 90 - course_diff + 360
  } else {
    corr_deg = 360 - course_diff + 90
  }

  let transverse_speed = sog * Math.cos(corr_deg * (Math.PI / 180))
  let lateral_speed_unformat = sog * Math.sin(corr_deg * (Math.PI / 180))
  let lateral_speed = Math.floor(lateral_speed_unformat * 100) / 100 // Setting format to 2 decimals

  // Bow radius
  let bow_circumference = 2 * Math.PI * radius_to_bow

  // Bow transverse speed
  let bow_one_degree_distance = bow_circumference / 360
  let bow_transverse_speed_meters_minute = rot * bow_one_degree_distance
  let rot_speed_bow = bow_transverse_speed_meters_minute / 30.867 // approximate value for conversion to knots
  let tot_bow_speed = rot_speed_bow + transverse_speed
  let bow_speed = Math.floor(tot_bow_speed * 100) / 100 // Setting format to 2 decimals

  // Stern radius
  let stern_circumference = 2 * Math.PI * radius_to_stern

  // Stern transverse speed
  let stern_one_degree_distance = stern_circumference / 360
  let stern_transverse_speed_meters_minute = -(rot * stern_one_degree_distance)

  let rot_speed_stern = stern_transverse_speed_meters_minute / 30.867 // approximate value for conversion to knots
  let tot_speed_stern = rot_speed_stern + transverse_speed
  let stern_speed = Math.floor(tot_speed_stern * 100) / 100 // Setting format to 2 decimals

  return {
    heading: heading,
    rateOfTurn: rot,
    bowSpeed: bow_speed,
    lateralSpeed: lateral_speed,
    sternSpeed: stern_speed,
  }
}

export default AISToConning
