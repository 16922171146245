/*
    Services page
 */

// React
import React, { useContext, useEffect } from "react"
import contextMY from "../../context/MyContext"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ActivityCards from "../PageHome/Components/Cards"
import { Button } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  navBarEmptySpaceBehind: {
    height: "52px",
  },
  footerEmptySpaceBehind: {
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "330px",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    padding: "0.5rem 2rem",
  },
}))

const Services = () => {
  const context = useContext(contextMY)

  const classes = useStyles()

  useEffect(() => {
    context.updateTab(2)
    context.updateIndexServices("summary")
  }, [context])

  // Mail account request
  const sendMail = () => {
    window.open(
      "mailto:info@mari-sto.fi?subject=Initial Contact&body=%0A%0APlease provide the following information,%0AName: %0AE-mail: %0ACompany: %0A%0AThank you for reaching out! %0AWe will respond to you shortly, have a great day!"
    )
  }

  return (
    <div className={classes.body}>
      <div className={classes.navBarEmptySpaceBehind} />
      <ActivityCards />
      <br />
      <br />
      <Button className={classes.button} onClick={sendMail}>
        Contact Maristo
      </Button>
    </div>
  )
}

export default Services
