/*
    Map Displaying Traffic in an Area
    
 */

import Grid from "@material-ui/core/Grid"
import React, { useState, useEffect } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { FeatureGroup, LayersControl, MapContainer, Polygon, TileLayer } from "react-leaflet"
import { NmScale } from "@marfle/react-leaflet-nmscale"
import ReactLeafletGoogleLayer from "react-leaflet-google-layer"

// Styling
const useStyles = makeStyles((theme) => ({
  mapBody: {
    height: "100%",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  popupContiner: {
    minWidth: "32vw",
  },
  popupContinerTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "stretch",
  },
}))

const MapAIS = (props) => {
  const classes = useStyles()
  const [map, setMap] = useState(null)
  const [zoom, setZoom] = useState(14)

  useEffect(() => {
    // props.dataPos
    if (map != null) {
      map.setView([props.dataPos[1], props.dataPos[0]])
    }
  }, [props.dataPos])

  // Setting zoom value to keep zoom level on position update
  const handleMoveend = (e) => {
    setZoom(e.target._animateToZoom)
  }

  return (
    <Grid container className={classes.mapBody}>
      <Grid item xs={12} className={classes.body}>
        <MapContainer
          center={[props.dataPos[1], props.dataPos[0]]}
          whenCreated={setMap}
          zoom={zoom}
          onMoveend={(e) => handleMoveend(e)}
          style={{ width: "100%", height: "100%" }}
          scrollWheelZoom={true}
        >
          {/* Base Layers*/}
          <TileLayer attribution="" url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          {/* Layer control */}
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Satellite (Google)" checked={true}>
              <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: "AIzaSyA_bgo8__4JPcmgfqZ7PNB2VGF0rGDf6DQ" }} type={"satellite"} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Satellite (Arc)">
              <TileLayer
                attribution=""
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Terrain">
              <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Dark">
              <TileLayer attribution="" url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" />
            </LayersControl.BaseLayer>

            {/* 
               MAP OVERLAY 
            */}

            {/* SEA MARKS */}
            <LayersControl.Overlay name="Sea Marks" checked={true}>
              <TileLayer attribution="" url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" />
            </LayersControl.Overlay>

            {/* TRACK SOG */}
            <LayersControl.Overlay name="Vessel outline" checked={true}>
              <FeatureGroup>
                <Polygon positions={props.vesselOutline} color={"#2AB025"} radius={2}></Polygon>
              </FeatureGroup>
            </LayersControl.Overlay>
          </LayersControl>
          <NmScale />
        </MapContainer>
      </Grid>
    </Grid>
  )
}

export default MapAIS
