/*
    Base layout for whole app
 */

import React, { Component } from "react"
import Footer from "./Footer/Footer"
import NavigationBar from "./NavigationBar/NavBar"

class Base extends Component {
  render() {
    return (
      <>
        <NavigationBar />
        <div style={{ minHeight: "86.5vh", height: "100%", position: "relative", paddingTop: "3rem" }}>{this.props.children}</div>
        <Footer />
      </>
    )
  }
}

export default Base
