/*
    Service page, VR tour
 */

import React, { useContext } from "react"
import contextMY from "../../../context/MyContext"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import VRtourInfo from "./Compenents/VRtourInfo"

const useStyles = makeStyles((theme) => ({
  navBarEmptySpaceBehind: {},
  footerEmptySpaceBehind: {
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "330px",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1em",
    marginBottom: "1em",
  },
  textBox: {
    width: "90vw",
    maxWidth: "900px",
    marginTop: "2em",
    marginBottom: "2em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  iframe: {
    marginTop: "0.5em",
    width: "90vw",
    height: "80vh",
  },
}))

const DataAnalysis = () => {
  const classes = useStyles()
  const context = useContext(contextMY)
  context.updateIndexServices("tours")
  context.updateTab(2)

  return (
    <div>
      <VRtourInfo />
      <div className={classes.body}>
        <div className={classes.textBox}>
          <Typography variant="h2">Do you want to know more</Typography>
          <br />
          <Typography variant="h5">Take contact by mail or phone, we are glad to answer all your questions</Typography>
        </div>
      </div>
    </div>
  )
}

export default React.memo(DataAnalysis)
