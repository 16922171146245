/*
    Heading display
 */

import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    headingBox: {
        textAlign: 'center',
        marginTop: '0.5em'
    },
    headingNumber: {}
}));

const HeadingDisplay = ({heading}) => {
    const classes = useStyles();
    return (
        <div className={classes.headingBox}>
            <Typography variant={'h4'} className={classes.headingNumber}>{heading}°</Typography>
        </div>
    )
};

export default HeadingDisplay