/*
    Conning display
 */

import React, { useEffect, useState, useContext } from "react"
import MQTT from "paho-mqtt"
import ConningDisplay from "./conning-display/ConningDisplay"
import AISToConning from "./conning-display/utils/AISToConning"
import axios from "axios"
import makeStyles from "@material-ui/core/styles/makeStyles"
import PivotPoint from "./PivotPoint"
import Grid from "@material-ui/core/Grid"
import contextMY from "../../context/MyContext"
import Map from "./elements/MapAIS.jsx"
import LoadingOverlay from "react-loading-overlay"
import Fade from "@material-ui/core/Fade"

const tryDisconnecting = (client) => {
  if (client !== null) {
    try {
      client.disconnect()
    } catch (error) {
      // Do nothing, client is already disconnected.
    }
  }
}

const useStyles = makeStyles((theme) => ({
  box: {
    padding: "1rem",
    maxWidth: "1200px",
  },
  mapBox: {
    minHeight: "40vh",
  },
  loadingOverlay: {
    width: "100%",
    maxWidth: "1200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  showIn5: {
    animation: "cssAnimation 0s 5s forwards",
    opacity: "0",
  },
}))

const getAvrageOFArr = (Arr) => {
  let total = 0
  for (var i = 0; i < Arr.length; i++) {
    total += Arr[i]
  }
  let avg = total / Arr.length
  return avg
}

const ConningDisplayMAIN = (props) => {
  const classes = useStyles()
  const context = useContext(contextMY)
  const [client, setClient] = useState(null)
  const [bowLatSpeedList, setBowLatSpeedList] = useState([0, 0, 0])
  const [sternLatSpeedList, setSternLatSpeedList] = useState([0, 0, 0])
  const [ROTList, setROTList] = useState([0, 0, 0])
  const [pivot_vis, set_pivot_vis] = useState(35)
  const [pivot_point, set_pivot_point] = useState(0)
  const [loa, set_loa] = useState(100)

  const [vesselPos, setVesselPos] = useState([60, 25])
  const [vesselOutlin, setVesselOutlin] = useState([[60, 25]])
  const [dimensions, setDimensions] = useState({
    toBow: 10,
    toStern: 30,
    tosb: 3,
    tops: 3,
  })

  const [conningParameters, setConningParameters] = useState({
    heading: 0,
    rateOfTurn: 0,
    bowSpeed: 0,
    lateralSpeed: 0,
    sternSpeed: 0,
  })

  // Handling API request
  const clientChanged = (mmsi) => {
    if (client === null) {
      // console.log('client is null');
      setClient(new MQTT.Client("meri.digitraffic.fi", 61619, "testclient_" + Date.now()))
    } else {
      client.onConnectionLost = (response) => {
        // console.log(Date.now() + ' Connection lost:' + response.errorMessage)
      }

      client.onMessageArrived = async (message) => {
        context.updateWaitingOnAIS(false)

        const content = message.payloadString
        const json = JSON.parse(content)
        // console.log("DATA", json)
        setVesselPos(json.geometry["coordinates"])

        let rot = json.properties["rot"]
        const heading = json.properties["heading"]
        const cog = json.properties["cog"]
        const sog = json.properties["sog"]

        let x = await axios
          .get("https://meri.digitraffic.fi/api/v1/metadata/vessels/" + mmsi)
          .then(function (response) {
            // console.log(response.data)
            setDimensions({
              toBow: response.data.referencePointA,
              toStern: response.data.referencePointB,
              tosb: response.data.referencePointC,
              tops: response.data.referencePointD,
            })
            return {
              toBow: response.data.referencePointA,
              toStern: response.data.referencePointB,
              tosb: response.data.referencePointC,
              tops: response.data.referencePointD,
            }
          })
          .catch(function (error) {
            // handle error
            // console.log(error)
          })
          .finally(function () {
            // always executed
          })
        // console.log("To bow: " + x.toBow + " To Stern: " + x.toStern)
        if (Math.abs(rot) === 127) {
          // No valid rate of turn data, set it to 0
          rot = 0
        }

        //  ---------------- CALC outline ----------
        // -----------------------------------------------------
        // -----------------------------------------------------

        // calculating the distance and bearing to bow point
        function degrees_to_radians(degrees) {
          var pi = Math.PI
          return degrees * (pi / 180)
        }
        function radians_to_degrees(radians) {
          var pi = Math.PI
          return radians * (180 / pi)
        }

        const Outline_b = (to_bow, to_starboard, to_port, heading) => {
          let vertical = to_starboard - to_port / 2
          let brng = degrees_to_radians(heading)
          let d = Math.hypot(to_bow, vertical)
          return [d, brng]
        }

        // calculating bow pos
        function corner_a(D, lat, long) {
          let R = 6378.1
          let d = D[0]
          let brng = D[1]
          let lat1 = degrees_to_radians(lat)
          let lon1 = degrees_to_radians(long)
          let lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(brng))
          let lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(d / R) * Math.cos(lat1), Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2))
          lat2 = radians_to_degrees(lat2)
          lon2 = radians_to_degrees(lon2)
          return [lat2, lon2]
        }

        function corner_b(A, beam, heading, length) {
          let R = 6378.1
          let lat = A[0]
          let long = A[1]
          let half_beam = beam / 2
          let bow_angle_l = length - 7 * (length / 8)
          let Angle_b = degrees_to_radians(heading + (180 - (90 - radians_to_degrees(Math.atan2(bow_angle_l, half_beam)))))
          let d = Math.hypot(half_beam, bow_angle_l)
          let lat1 = degrees_to_radians(lat)
          let lon1 = degrees_to_radians(long)
          let lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(Angle_b))
          let lon2 =
            lon1 + Math.atan2(Math.sin(Angle_b) * Math.sin(d / R) * Math.cos(lat1), Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2))
          lat2 = radians_to_degrees(lat2)
          lon2 = radians_to_degrees(lon2)
          return [lat2, lon2]
        }

        function corner_c(B, heading, length) {
          let R = 6378.1
          let lat = B[0]
          let long = B[1]
          let bow_angle_l = length - length / 8
          let Angle_b = degrees_to_radians(heading + 180)
          let d = bow_angle_l
          let lat1 = degrees_to_radians(lat)
          let lon1 = degrees_to_radians(long)
          let lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(Angle_b))
          let lon2 =
            lon1 + Math.atan2(Math.sin(Angle_b) * Math.sin(d / R) * Math.cos(lat1), Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2))
          lat2 = radians_to_degrees(lat2)
          lon2 = radians_to_degrees(lon2)
          return [lat2, lon2]
        }

        function corner_d(C, beam, heading) {
          let R = 6378.1
          let lat = C[0]
          let long = C[1]
          let Angle_b = degrees_to_radians(heading + 270)
          let d = beam
          let lat1 = degrees_to_radians(lat)
          let lon1 = degrees_to_radians(long)
          let lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(Angle_b))
          let lon2 =
            lon1 + Math.atan2(Math.sin(Angle_b) * Math.sin(d / R) * Math.cos(lat1), Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2))
          lat2 = radians_to_degrees(lat2)
          lon2 = radians_to_degrees(lon2)
          return [lat2, lon2]
        }

        function corner_e(D, heading, length) {
          let R = 6378.1
          let lat = D[0]
          let long = D[1]
          let bow_angle_l = length - length / 8
          let Angle_b = degrees_to_radians(heading)
          let d = bow_angle_l
          let lat1 = degrees_to_radians(lat)
          let lon1 = degrees_to_radians(long)
          let lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) + Math.cos(lat1) * Math.sin(d / R) * Math.cos(Angle_b))
          let lon2 =
            lon1 + Math.atan2(Math.sin(Angle_b) * Math.sin(d / R) * Math.cos(lat1), Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2))
          lat2 = radians_to_degrees(lat2)
          lon2 = radians_to_degrees(lon2)
          return [lat2, lon2]
        }

        // Converintg to km
        let to_bow_edited = x.toBow / 1000
        let to_port_edited = x.tops / 1000
        let to_stern_edited = x.toStern / 1000
        let to_starboard_edited = x.tosb / 1000

        // Calc dimensions
        let beam = to_starboard_edited + to_port_edited
        let length = to_bow_edited + to_stern_edited

        // Calc distance to bow point
        let Dout = Outline_b(to_bow_edited, to_starboard_edited, to_port_edited, heading)

        // Calc corners
        let lat = json.geometry["coordinates"][1]
        let long = json.geometry["coordinates"][0]

        let A = corner_a(Dout, lat, long)
        let B = corner_b(A, beam, heading, length)
        let C = corner_c(B, heading, length)
        let D = corner_d(C, beam, heading)
        let E = corner_e(D, heading, length)

        setVesselOutlin([A, B, C, D, E])
        // -----------------------------------------------------

        const conningParameters = AISToConning(rot, heading, cog, sog, x.toBow, x.toStern)

        // ROT
        let rotList = ROTList
        rotList.push(rot)
        rotList.shift()
        setROTList(rotList)
        conningParameters.rateOfTurn = getAvrageOFArr(rotList).toFixed(1)

        // Bow lateral speed
        let latBowSpeed = bowLatSpeedList
        latBowSpeed.push(conningParameters.bowSpeed)
        latBowSpeed.shift()
        setBowLatSpeedList(latBowSpeed)
        conningParameters.bowSpeed = getAvrageOFArr(latBowSpeed).toFixed(2)

        // Stern lateral speed
        let latSternSpeed = sternLatSpeedList
        latSternSpeed.push(conningParameters.sternSpeed)
        latSternSpeed.shift()
        setSternLatSpeedList(latSternSpeed)
        conningParameters.sternSpeed = getAvrageOFArr(latSternSpeed).toFixed(2)

        setConningParameters(conningParameters)

        // Pivot Point init
        let to_aft = x.toStern
        let to_front = x.toBow
        let tot_bow_speed = parseFloat(conningParameters.bowSpeed)
        let tot_aft_speed = parseFloat(conningParameters.sternSpeed)

        let tot_leangth = to_aft + to_front
        set_loa(tot_leangth)

        let p_point = 0

        if (tot_aft_speed < 0 && tot_bow_speed > 0) {
          let tot_aft_speed_abs = Math.abs(tot_aft_speed)
          let pivot_tot = tot_aft_speed_abs + tot_bow_speed
          let pivot_rel = tot_aft_speed_abs / pivot_tot
          p_point = pivot_rel * tot_leangth
          set_pivot_point(p_point)
        } else if (tot_bow_speed < 0 && tot_aft_speed > 0) {
          let pivot_tot = Math.abs(tot_bow_speed) + tot_aft_speed
          let pivot_rel = Math.abs(tot_bow_speed) / pivot_tot
          p_point = pivot_rel * tot_leangth
          set_pivot_point(p_point)
        } else if (tot_aft_speed < 0 && tot_bow_speed < 0 && tot_aft_speed < tot_bow_speed) {
          let pivot_tot = Math.abs(tot_aft_speed) + tot_bow_speed
          let pivot_rel = Math.abs(tot_aft_speed) / pivot_tot
          p_point = pivot_rel * tot_leangth
          set_pivot_point(p_point)
        } else if (tot_aft_speed < 0 && tot_bow_speed < 0 && tot_aft_speed > tot_bow_speed) {
          let pivot_tot = Math.abs(tot_aft_speed) + tot_bow_speed
          let pivot_rel = Math.abs(tot_aft_speed) / pivot_tot
          p_point = pivot_rel * tot_leangth
          set_pivot_point(p_point)
        } else if (tot_aft_speed > 0 && tot_bow_speed > 0 && tot_aft_speed < tot_bow_speed) {
          let pivot_tot = -tot_aft_speed + tot_bow_speed
          let pivot_rel = tot_aft_speed / pivot_tot
          p_point = -1 * pivot_rel * tot_leangth
          set_pivot_point(p_point)
        } else if (tot_aft_speed > 0 && tot_bow_speed > 0 && tot_aft_speed > tot_bow_speed) {
          let pivot_tot = -tot_aft_speed + tot_bow_speed
          let pivot_rel = tot_aft_speed / pivot_tot
          p_point = Math.abs(pivot_rel * tot_leangth)
          set_pivot_point(p_point)
        }

        // Set pivot in visualisation
        if (p_point > tot_leangth) {
          set_pivot_vis(50)
        } else if (0 < p_point && p_point < tot_leangth) {
          let procentage = p_point / tot_leangth
          set_pivot_vis(procentage * 50)
        } else {
          set_pivot_vis(0)
        }
      }

      const connectionProperties = {
        onSuccess: () => {
          client.subscribe(`vessels/${props.mmsi}/locations`)
        },
        mqttVersion: 4,
        useSSL: true,
        userName: "digitraffic",
        password: "digitrafficPassword",
      }

      client.connect(connectionProperties)
    }
  }

  useEffect(() => {
    clientChanged(context.vesselMMSI)
    return () => {
      tryDisconnecting(client)
    }
  }, [client, context.vesselMMSI])

  return (
    <LoadingOverlay
      active={context.waitingOnAIS}
      spinner
      text={
        <div>
          <h3>Looking for AIS data... </h3>
          <Fade in={context.waitingOnAIS} style={{ transitionDelay: "5000ms" }}>
            <p className={classes.showIn5}>
              <i>Vessel might be out of range if not found after 10 seconds</i>
            </p>
          </Fade>
        </div>
      }
      className={classes.loadingOverlay}
    >
      <Grid container className={classes.box}>
        <Grid item xs={9} md={5}>
          <ConningDisplay
            heading={conningParameters.heading}
            rateOfTurn={conningParameters.rateOfTurn}
            bowSpeed={conningParameters.bowSpeed}
            lateralSpeed={conningParameters.lateralSpeed}
            sternSpeed={conningParameters.sternSpeed}
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <PivotPoint pivot_point_done={pivot_point} vis_dis={pivot_vis} loa={loa} />
        </Grid>
        <Grid item xs={12} md={5} className={classes.mapBox}>
          <Map dataPos={vesselPos} mapCenter={vesselPos} dimensions={dimensions} vesselOutline={vesselOutlin} />
        </Grid>
      </Grid>
    </LoadingOverlay>
  )
}

export default ConningDisplayMAIN
