/*
    Component NAME
 */

import React, { useState } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ConningDisplay from "./Componnets/ConningDisplay"
import VesselPicker from "./Componnets/VesselPicker"
import { Grid } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const ConningDashboard = (props) => {
  const classes = useStyles()

  const [selectedMMSI, setSelectedMMSI] = useState(230629000)

  return (
    <>
      <Grid item xs={12} className={classes.center}>
        <VesselPicker mmsi={selectedMMSI} setMMSI={setSelectedMMSI} />
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <ConningDisplay mmsi={selectedMMSI} />
      </Grid>
    </>
  )
}

export default ConningDashboard
