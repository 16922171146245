import firebase from "firebase"

let config = {
  apiKey: "AIzaSyD-ny0UO0Sizp8Ho96XHLDHQ-LnY59S62A",
  authDomain: "maristo-dev.firebaseapp.com",
  databaseURL: "https://maristo-dev.firebaseio.com",
  projectId: "maristo-dev",
  storageBucket: "maristo-dev.appspot.com",
  messagingSenderId: "532720643693",
  appId: "1:532720643693:web:7dd8ca73847113a1b69964",
  measurementId: "G-88ZG84L5GC",
}

firebase.initializeApp(config)
firebase.analytics()

export default firebase
