/*
    General Article Page
 */

import React from 'react';
import NavBar from "../../Base/NavigationBar/NavBar";
import Footer from "../../Base/Footer/Footer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
// Pic
import TestPic from '../../../Resources/Building_Maristo.png'

const useStyles = makeStyles(theme => ({
    navBarEmptySpaceBehind: {
        height: '52px'
    },
    footerEmptySpaceBehind: {
        height: '200px',
        [theme.breakpoints.down('xs')]: {
            height: '330px',
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        margin: '2em 0em'
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        justifyItems: 'center',
        textDecoration: 'none',
        width: '95vw',
    },
    img: {
        width: '95%',
    },
    textBox: {
        padding: '1em'
    },
    title: {
        margin: '1em 0em'
    },
    date: {
        paddingTop: '5px'
    },
    imgBox: {
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        width: '80vw'
    },

}));

const AboutUs = props => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <NavBar/>
            <div className={classes.navBarEmptySpaceBehind}/>
            <div className={classes.body}>
                <Paper className={classes.paper}>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="flex-start"
                          className={classes.gridContainer}>

                        <Grid item sx={12} className={classes.imgBox}>
                            <Typography variant={"h3"} className={classes.title}>The article is coming soon</Typography>
                        </Grid>

                        <Grid item sx={12} className={classes.imgBox}>
                            <img src={TestPic} alt="" className={classes.img}/>
                        </Grid>

                        <Grid item sm={12} md={8} className={classes.textBox}>
                            <Typography variant={"body1"} className={classes.text}>Text coming soon</Typography>
                            <Typography variant={"caption"} className={classes.date}>2019</Typography>
                        </Grid>

                    </Grid>
                </Paper>
            </div>
            <div className={classes.footerEmptySpaceBehind}/>
            <Footer/>
        </React.Fragment>
    );
};

export default React.memo(AboutUs);