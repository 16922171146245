/*
    Setting up Global Context State and Initial values
 */

import React, { useState } from "react"
import MyContext from "./MyContext"

const GlobalState = (props) => {
  // Tab index
  const [tab, setTab] = useState(0)
  const updateTab = (num) => {
    setTab(num)
  }

  // --------- HUB Navigation bar, tab number  -----------
  const [tabH, setTabHUB] = useState(0)
  const updateTabH = (num) => {
    setTabHUB(num)
  }

  // --------- HUB Profile page menu number  -----------
  const [hubMenuNum, setHubMenu] = useState(0)
  const updateHubMenuNum = (num) => {
    setHubMenu(num)
  }

  // --------- Drop Menu Services -----------
  //  Index
  const [indexServices, setIndexServices] = useState(0)
  const updateIndexServices = (num) => {
    setIndexServices(num)
  }

  // --------- Side Menu  -----------
  //  Index
  const [sideMenuIsOpen, upSideMenuIsOpen] = useState(false)
  const setSideMenuIsOpen = (val) => {
    upSideMenuIsOpen(val)
  }

  //  Open
  const [linkDropDownServices, setLinkServices] = useState(false)
  const openDropDownServices = () => {
    setLinkServices(true)
    closeEvent()
  }
  // Click away
  const closeEvent = () => {
    document.addEventListener("click", closeDropDownServices)
  }
  // Close
  const closeDropDownServices = () => {
    setLinkServices(false)
  }

  // --------- Conning Display -----------
  const [vesselMMSI, setVesselMMSI] = useState("230629000")
  const updateVesselMMSI = (mmsi) => {
    setVesselMMSI(mmsi)
  }

  const [toBow, setToBow] = useState(50)
  const [toStern, setToStern] = useState(50)
  const updateToStern = (num) => {
    setToStern(num)
  }

  const GetVesselMetrics = (mmsi) => {
    setToBow(22)
  }

  // Determine if we are waiting for messages
  const [waitingOnAIS, setWaitingOnAIS] = useState(true)
  const updateWaitingOnAIS = (isWaiting) => {
    setWaitingOnAIS(isWaiting)
  }

  return (
    <MyContext.Provider
      value={{
        tabIndex: tab,
        updateTab: updateTab,

        tabIndexHUB: tabH,
        updateTabHUB: updateTabH,
        hubMenuNum: hubMenuNum,
        updateHubMenuNum: updateHubMenuNum,

        linkDropDownServices: linkDropDownServices,
        openDropDownServices: openDropDownServices,
        closeDropDownServices: closeDropDownServices,
        indexServices: indexServices,
        updateIndexServices: updateIndexServices,

        sideMenuIsOpen: sideMenuIsOpen,
        setSideMenuIsOpen: setSideMenuIsOpen,

        toBow: toBow,
        toStern: toStern,
        updateToStern: updateToStern,
        GetVesselMetrics: GetVesselMetrics,
        vesselMMSI: vesselMMSI,
        updateVesselMMSI: updateVesselMMSI,
        waitingOnAIS: waitingOnAIS,
        updateWaitingOnAIS: updateWaitingOnAIS,
      }}
    >
      {props.children}
    </MyContext.Provider>
  )
}

export default GlobalState
