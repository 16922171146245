/*
    News Feed
 */

import React, { useContext } from "react"
import contextMY from "../../context/MyContext"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"
import NewsFeed from "./Components/NewsFeed"

const useStyles = makeStyles((theme) => ({
  navBarEmptySpaceBehind: {
    height: "52px",
  },
  footerEmptySpaceBehind: {
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "330px",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  girdItem: {
    maxWidth: "80em",
  },
}))

const News = () => {
  const classes = useStyles()
  const context = useContext(contextMY)
  context.updateTab(3)

  return (
    <div className={classes.body}>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={10} className={classes.girdItem}>
          <NewsFeed />
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(News)
