/*
    Location element 
 */

import React from "react"
import { Typography, Grid } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FinFlag from "../../../Resources/Flags/Fin.svg"
import SweFlag from "../../../Resources/Flags/swe.svg"

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1em 0em",
    padding: "1em 2em",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 0rem",
    background: "#D7D7D9",
    marginTop: "2rem",
    marginBottom: "0.5rem",
  },

  gridContainer: {
    maxWidth: "1200px",
  },
  gridItem: {
    paddingLeft: "2rem",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  centerRow: {
    with: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textBox2: {
    marginTop: "0.5rem",
    marginBottom: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flag: {
    maxHeight: "4rem",
    marginRight: "8rem",
  },
}))

const ProcessAutomation = () => {
  const classes = useStyles()

  return (
    <div className={classes.body}>
      <Grid container alignContent="center" alignItems="center" className={classes.gridContainer}>
        <Grid item xs={12} className={classes.center}>
          <Typography variant="h3">Find us </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Typography variant="h4">Finland, Turku - Head Office </Typography>
          <br />

          <div className={classes.textBox2}>
            <Typography variant="subtitle1" className={classes.text}>
              info@mari-sto.fi
              <br />
              +358 50 495 6942
            </Typography>
            <img src={FinFlag} alt="" className={classes.flag} />
          </div>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Typography variant="h4">Sweden, Gothenburg</Typography>
          <br />
          <div className={classes.textBox2}>
            <Typography variant="subtitle1" className={classes.text}>
              info@mari-sto.fi
              <br />
              +46 73 960 6160
            </Typography>
            <img src={SweFlag} alt="" className={classes.flag} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
} // Class END

export default ProcessAutomation
