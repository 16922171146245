/*
    LogoImg
 */

// React
import React from 'react';
import {Link} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import logo from "../../../../Resources/Logo.png";


const useStyles = makeStyles(theme => ({
    logoBox: {
        display: 'flex',
        height: '100%'
    },
    logo: {
        width: '100%',
        height: '100%',
        minWidth: '100px',
        maxWidth: '180px',
        '&:hover': {
            transform: 'scale(1.1, 1.1)'
        },
    },
}));


const Logo = (props) => {

    const classes = useStyles();

    return (

        <div className={classes.logoBox}>
            <Link to="/">
                <img src={logo} alt="" width="20%" className={classes.logo}/>
            </Link>
        </div>
    );
};

export default Logo;