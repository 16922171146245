/*
    Process Automation 
    - Description
 */

// React
import React, { useContext } from "react"
import contextMY from "../../../context/MyContext"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import Pic1 from "../../../Resources/Icone/Robot.svg"


const useStyles = makeStyles((theme) => ({
  navBarEmptySpaceBehind: {
    height: "52px",
  },
  footerEmptySpaceBehind: {
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "330px",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1em",
  },
  list: {
    marginTop: "1em",
  },
  textBox: {
    width: "90vw",
    maxWidth: "900px",
    marginBottom: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  pic1: {
    height: "20vh",
    minHeight: "10rem",

    borderRadius: "1em",
    margin: "2em",
  },
}))

const DataAnalysis = (props) => {
  const context = useContext(contextMY)
  context.updateTab(2)
  context.updateIndexServices("automation")

  const classes = useStyles()

  return (
    <div className={classes.body}>
      <div className={classes.textBox}>
        <Typography variant="h2">Process Automation</Typography>
        <img src={Pic1} alt="" className={classes.pic1} />

        <br />
        <Typography variant="body1">
          Automating work processes enable a variety of new opportunities for generate additional value in multiple ways where few are time
          saving, consonance and accuracy, freeing up human resources from repetitive tasks and speeding up workflows. Automations solutions
          can offer an immense competitive edge in improving efficiency in existing business processes. At Maristo, we aim to identify our
          needs and provides free guides in identify areas where automations can be inserted in your operations.
          <div className={classes.list}>
            In the maritime industry, it can for instance:
            <ul>
              <li>Assist in forms that need to be processed in to an maintenance program </li>
              <li>Detect incoming invoices and add them to an payment que where the human only need to accept</li>
              <li>Help in information collection for gaining better insights </li>
            </ul>
          </div>
          By eliminating boring and reparative task can dramatically improve the wellbeing of your employees.
        </Typography>
      </div>
    </div>
  )
}

export default React.memo(DataAnalysis)
