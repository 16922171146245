/*
    Drop down manu or links
 */

// React
import React, { useContext } from "react"
import contextMY from "../../../../context/MyContext"
// Material UI
import makeStyles from "@material-ui/core/styles/makeStyles"
// Components
import Paper from "@material-ui/core/Paper"
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"
import ROUTES from "../../../../ROUTES"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  dropMenu: {
    position: "absolute",
    top: "52px",
    left: "45%",
  },
}))

const DropDownMenuServices = () => {
  const classes = useStyles()
  const context = useContext(contextMY)
  return (
    <>
      {context.linkDropDownServices ? (
        <div className={classes.dropMenu} onMouseLeave={context.closeDropDownServices}>
          <Paper className={classes.paper}>
            <MenuList>
              <MenuItem button component={Link} to={ROUTES.SERVICE_DATA} selected={context.indexServices === 'data'}>
                {"Data Driven Insights"}
              </MenuItem>
              <MenuItem button component={Link} to={ROUTES.SERVICE_PROCESS_AUTOMATION} selected={context.indexServices === 'automation'}>
                {"Process Automation"}
              </MenuItem>
              <MenuItem button component={Link} to={ROUTES.SERVICE_SOFTWARE_SOLUTIONS} selected={context.indexServices === 'software'}>
                {"Software Solutions"}
              </MenuItem>
              <MenuItem button component={Link} to={ROUTES.SERVICE_VIRTUAL_TOURS} selected={context.indexServices === 'tours'}>
                {"Virtual Ships Tours"}
              </MenuItem>
            </MenuList>
          </Paper>
        </div>
      ) : null}
    </>
  )
}

export default DropDownMenuServices
