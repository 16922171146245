/*
    Generating context variables

    -  Variables in this function will always be overwritten
    -  This file is mostly for better auto complication
*/

import React from "react"

export default React.createContext({
  tabIndex: 0,
  updateTab: (num) => {},

  tabIndexHUB: 0,
  updateTabHUB: (num) => {},
  hubMenuNum: 0,
  updateHubMenuNum: (num) => {},

  linkDropDownServices: false,
  openDropDownServices: (event) => {},
  closeDropDownServices: () => {},
  indexServices: 0,
  updateIndexServices: (num) => {},

  sideMenuIsOpen: false,
  setSideMenuIsOpen: (val) => {},

  add: (num) => {},

  toStern: 0,
  toBow: 0,
  GetVesselMetrics: (mmsi) => {},
  vesselMMSI: "",
  updateVesselMMSI: (mmsi) => {},
  waitingOnAIS: false,
  updateWaitingOnAIS: (isWaiting) => {},
})
