import React from 'react'
import ArrowPortOff from './resources/Arrow_port_off.svg'
import ArrowPortOn from './resources/Arrow_port_on.svg'
import ArrowStarboardOff from './resources/Arrow_starboard_off.svg'
import ArrowStarboardOn from './resources/Arrow_starboard_on.svg'
import ArrowForwardOff from './resources/Arrow_forward_off.svg'
import ArrowForwardOn from './resources/Arrow_forward_on.svg'
import ArrowBackwardsOff from './resources/Arrow_backwards_off.svg'
import ArrowBackwardsOn from './resources/Arrow_backwards_on.svg'
import vesselPic from './resources/bow.svg'
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    centerBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    img: {
        position: 'absolute',
        zIndex: '10',
        height: '50vh',
    },
    gridContainer: {
        marginBottom: '1em',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: 'auto 100% auto',
        height: '50vh',
        textAlign: 'center',
        zIndex: '100',
    },
    row1: {
        height: '5vh'
    },

    portArrow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    starboardArrow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    forwardArrow: {
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    backwardsArrow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    svg: {
        height: '100%'
    },
    arrow:{
        height: '4vh'
    },
    arrowOFF:{
        height: '4vh',
        opacity: '0.5'
    },
    speedNumbers:{
        fontSize: '4vh'
    }
}));

const Vessel = ({bowSpeed, lateralSpeed, sternSpeed}) => {
    const classes = useStyles();
    return (
        // TODO: Separate this thing into its own components
        <div className={classes.centerBox}>
            <img src={vesselPic} alt="" className={classes.img}/>
            <div className={classes.gridContainer}>
                {/* Empty row */}
                <div className={classes.row1}></div>
                <div className={classes.row1}></div>
                <div className={classes.row1}></div>

                {/* BOW speed row */}
                <div className={classes.portArrow}>
                    {bowSpeed > -0.01 ?
                        <img src={ArrowPortOff} alt="" className={classes.arrowOFF}/>
                        :
                        <img src={ArrowPortOn} alt="" className={classes.arrow}/>
                    }
                </div>
                <div>
                    <Typography variant={"h3"} className={classes.speedNumbers}>{bowSpeed} kts </Typography>
                </div>
                <div className={classes.starboardArrow}>
                    {bowSpeed < 0.01 ?
                        <img src={ArrowStarboardOff} alt="" className={classes.arrowOFF}/>
                        :
                        <img src={ArrowStarboardOn} alt="" className={classes.arrow}/>
                    }
                </div>

                {/* Forward speed row */}
                <div className={classes.item}/>
                <div className={classes.forwardArrow}>
                    {lateralSpeed < 0 ?
                        <img src={ArrowForwardOff} alt="" className={classes.arrowOFF}/>
                        :
                        <img src={ArrowForwardOn} alt="" className={classes.arrow}/>
                    }
                </div>
                <div className={classes.item}></div>

                <div className={classes.item}></div>
                <div className={classes.item}>
                    <Typography variant={'h3'}className={classes.speedNumbers}>{lateralSpeed} kts</Typography>
                </div>
                <div className={classes.item}></div>

                {/* Backwards speed row */}
                <div className={classes.item}></div>
                <div className={classes.backwardsArrow}>
                    {lateralSpeed > 0 ?
                        <img src={ArrowBackwardsOff} alt="" className={classes.arrowOFF}/>
                        :
                        <img src={ArrowBackwardsOn} alt="" className={classes.arrow}/>
                    }
                </div>
                <div className={classes.item}></div>

                {/*Stern speed row*/}
                <div className={classes.portArrow}>
                    {sternSpeed > -0.01 ?
                        <img src={ArrowPortOff} alt="" className={classes.arrowOFF}/>
                        :
                        <img src={ArrowPortOn} alt="" className={classes.arrow}/>
                    }
                </div>
                <div className={classes.centering}>
                    <Typography variant={'h3'} className={classes.speedNumbers}>{sternSpeed} kts </Typography>
                </div>
                <div className={classes.starboardArrow}>
                    {sternSpeed < 0.01 ?
                        <img src={ArrowStarboardOff} alt="" className={classes.arrowOFF}/>
                        :
                        <img src={ArrowStarboardOn} alt="" className={classes.arrow}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default Vessel