/*
    Marketing Hub site element
 */

import React from "react"
import { Typography, Grid } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ImgBridge from "../../../Resources/Pictures/Fair-Aboa-Mare.jpg"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1em 0em",
    padding: "1em 2em",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    textAlign: "center",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 0rem",
    transform: "skewY(3deg)",
    background: theme.palette.primary.light,
    marginTop: "0.4rem",
    marginBottom: "0.5rem",
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2em",
    transform: "skewY(-3deg)",
    color: "#fff",
  },
  gridContainer: {
    marginTop: "4rem",
    maxWidth: "1200px",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textBox2: {
    maxWidth: "600px",
    marginTop: "0.5rem",
    marginBottom: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    maxHeight: "20rem",
    borderRadius: "0.3rem",
  },
}))

const ProcessAutomation = () => {
  const classes = useStyles()

  return (
    <div className={classes.center}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} md={6} className={classes.center}></Grid>

        <Grid item xs={12} md={6} className={classes.center}>
          <Typography variant="h3">Who are we... </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={6} className={classes.center}>
          <img src={ImgBridge} alt="" className={classes.img} />
        </Grid>

        <Grid item xs={12} md={6} className={classes.center}>
          <div className={classes.textBox2}>
            <Typography variant="body1" className={classes.text}>
              We are a young, professional team with field experience from working onboard cargo vessels and cruise ships, operating
              multipurpose vessel and project development in a variety of industry research projects, machine learning and automation
              projects. We have been involved in establishing the first cyber security course for mariners in Finland as well. While half of
              our team members have experience from the marine-side, the other half have master's degrees in economics as well as in machine
              learning.
              <br />
              <br />
              We are passionate about implementing the right digital tool to achieve business objectives and increase the value proposition,
              along with better quality and service.
            </Typography>
          </div>

          <Button className={classes.button} href={"https://hub.mari-sto.fi/"}>
            <Typography variant="button">Find out more</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  )
} // Class END

export default ProcessAutomation
