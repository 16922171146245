/*
    References
 */

// React
import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import Pic1 from "../../../Resources/Logos/DeductiveLabs.png"
import Pic2 from "../../../Resources/Logos/AboaMare.png"
import PicHA from "../../../Resources/Logos/HA.png"
import PicVL from "../../../Resources/Logos/viking-line-logo-header.png"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  gridContainer: {
    marginBottom: "1em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "90vw",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  title: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pic1: {
    width: "90%",
    maxHeight: "10rem",
    objectFit: "contain",
    [theme.breakpoints.up("md")]: {},
  },
}))

const DataAnalysis = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.body}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.title}>
            Trusted by
          </Typography>
        </Grid>

        <Grid item xs={6} sm={5} md={3} className={classes.gridItem}>
          <a href="https://www.vikingline.com/" className={classes.link}>
            <img src={PicVL} alt="" className={classes.pic1} />
          </a>
        </Grid>

        <Grid item xs={6} sm={5} md={3} className={classes.gridItem}>
          <a href="https://www.aboamare.fi/" className={classes.link}>
            <img src={Pic2} alt="" className={classes.pic1} />
          </a>
        </Grid>

        <Grid item xs={6} sm={5} md={3} className={classes.gridItem}>
          <a href="https://deductivelabs.com/" className={classes.link}>
            <img src={Pic1} alt="" className={classes.pic1} />
          </a>
        </Grid>

        <Grid item xs={6} sm={5} md={3} className={classes.gridItem}>
          <a href="https://www.ha.ax/" className={classes.link}>
            <img src={PicHA} alt="" className={classes.pic1} />
          </a>
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(DataAnalysis)
