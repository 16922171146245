/*
    Marketing and contact

 */

import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { Typography, Button } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
    marginBottom: "1em",
    padding: "0rem 5vw",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  gridItem2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2em",
  },
  button: {
    padding: "0.5rem 2rem",
  },
  title: {
    textAlign: "center",
    marginBottom: "2rem",
    maxWidth: "1200px",
  },
}))

const ContactText = () => {
  const classes = useStyles()

  // Mail account request
  const sendMail = () => {
    window.open(
      "mailto:info@mari-sto.fi?subject=Initial Contact&body=%0A%0APlease provide the following information,%0AName: %0AE-mail: %0ACompany: %0A%0AThank you for reaching out! %0AWe will respond to you shortly, have a great day!"
    )
  }

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography variant="h4" className={classes.title}>
          We are accelerating the implementation of information systems to the maritime sector to provide value at the right place at the
          right
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridItem2}>
        <Button className={classes.button} onClick={sendMail}>
          Contact Maristo
        </Button>
      </Grid>
    </Grid>
  )
}

export default ContactText
