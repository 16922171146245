/*
    Marketing Hub site element
 */

import React from "react"
import { Typography, Grid } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import ImgBridge from "../../../Resources/Pictures/GraceLang.png"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import ROUTS from "../../../ROUTES"

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1em 0em",
    padding: "0.5em 2em",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    textAlign: "center",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 0rem",
    transform: "skewY(3deg)",
    background: theme.palette.primary.main,
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2em",
    transform: "skewY(-3deg)",
    color: "#fff",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textBox2: {
    maxWidth: "500px",
    marginBottom: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  gridContainer: {
    width: "100vw",
    maxWidth: "1400px",
  },
  img: {
    height: "20rem",

    borderRadius: "0.3rem",
    objectFit: "cover",
  },
}))

const SoftwareSolutions = () => {
  const classes = useStyles()

  return (
    <div className={classes.body}>
      <div className={classes.body2}>
        <Grid container alignContent="center" className={classes.gridContainer}>
          <Grid item xs={12} md={6} className={classes.center}>
            <div className={classes.textBox2}>
              <Typography variant="h3">Software Solutions</Typography>
              <br />
              <Typography variant="body1" className={classes.text}>
                Do you have an idea or vision of a software solutions that you want? We can help you make it a reality, either by in-house
                development or assist in the development process with maritime expertise.
              </Typography>
            </div>

            <Button className={classes.button} component={Link} to={ROUTS.SERVICE_SOFTWARE_SOLUTIONS}>
              <Typography variant="button">Explore & checkout DEMO</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={6} className={classes.center}>
            <img src={ImgBridge} alt="" className={classes.img} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default SoftwareSolutions
