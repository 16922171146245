/*
 *   Front page
 *
 */

import React, { useContext, useEffect } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import contextMY from "../../context/MyContext"
import HeaderTextPic from "./Components/HeaderTextPic"
import HeaderPicText from "./Components/HeaderPicText"
import ActivityCards from "./Components/Cards"
import PicHead1 from "../../Resources/Sunsetskagen.jpg"
import PicHead2 from "../../Resources/Pictures/TheOffice_2.jpg"
import SoftwareSolutions from "./Components/SoftwareSolutions"
import Reference from "./Components/Reference"
import ContactText from "./Components/ContactText"
import Automation from "./Components/Automation"
import DataInsights from "./Components/DataInsights"
import VRTours from "./Components/VR_tours"
import WhoAreWe from "./Components/WhoAreWe"
import Locations from "./Components/Locations"
import ReactGA from "react-ga"
import FireB from "../../FirebaseMy"

const useStyles = makeStyles((theme) => ({
  space_2: {
    height: "2em",
    backgroundColor: theme.palette.primary.white,
  },
  space_23: {
    paddingTop: "10vw",
    backgroundColor: theme.palette.primary.white,
  },
  space_22: {
    height: "8vw",
    marginTop: "-6rem",
    transform: "skewY(5deg)",
    index: "-10",
    backgroundColor: theme.palette.primary.white,
  },
  space_3: {
    height: "5em",
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileHide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  navBarEmptySpaceBehind: {
    height: "52px",
  },
  space_1: {
    height: "5px",
  },
  pressDownFooter: {
    height: "50vh",
  },
  center: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  footerEmptySpaceBehind: {
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "330px",
    },
  },
}))

function Home() {
  const classes = useStyles()
  ReactGA.pageview(window.location.pathname + window.location.search)
  const context = useContext(contextMY)

  useEffect(() => {
    context.updateTab(0)
    context.updateIndexServices(0)

    FireB.analytics().logEvent("notification_received")

    ReactGA.event({
      category: "Home page",
      action: "Test 1",
    })
  }, [context])

  return (
    <>
      <HeaderTextPic image={PicHead1} />
      <div className={classes.space_1} />
      <HeaderPicText image={PicHead2} />

      <ActivityCards />

      <ContactText />

      {/* Services */}

      <DataInsights />
      <Automation />
      <SoftwareSolutions />
      <VRTours />

      {/* Who are we */}
      <WhoAreWe />

      <Locations />

      <div className={classes.space_3} />
      <Reference />
      <div className={classes.space_3} />
    </>
  )
}

export default Home
