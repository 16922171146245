/*
    Card component with hover effect
    - Picture
    - Title

 */

import React from "react"
import { Link } from "react-router-dom"
import { Grid, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    textDecoration: "none",
    color: "#000",
    maxWidth: "1000px",
  },
  imgBox: {
    height: "11rem",
    minHeight: "10rem",
    display: "flex",

    justifyContent: "center",
  },
  img: {
    height: "90%",
    "&:hover": {
      height: "100%",
    },
  },
  title: {},
  button: {},
}))

const Card = (props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.gridContainer} component={Link} to={props.linkTo}>
      <Grid item xs={12}>
        <div className={classes.imgBox}>
          <img src={props.img} alt={props.title} className={classes.img} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h5"} className={classes.title}>
          {props.title}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Card
