/*
    Component NAME
 */

import React, { useContext, useEffect } from "react"
import NavBar from "../../Base/NavigationBar/NavBar"
import contextMY from "../../../context/MyContext"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import ConningDashboard from "../../../AppConnigDisplay/ConningDashboard"
import Build_img from "../../../Resources/Building_Maristo.png"
import { Grid } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  navBarEmptySpaceBehind: {
    height: "52px",
  },
  footerEmptySpaceBehind: {
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "330px",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  body1: {
    maxWidth: "900px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    width: "90vw",
    maxWidth: "900px",
  },
  title: {
    marginTop: "0.5em",
  },
  space_1: {
    height: "2em",
  },
  img: {
    height: "30vh",
  },
  marineTraffic: {
    minHeight: "500px",
  },
  gridContainer: {
    marginTop: "3rem",
    marginBottom: '3rem'
  },
}))

const SoftwareSolutions = (props) => {
  const context = useContext(contextMY)
  context.updateIndexServices('software')
  context.updateTab(2)
  const classes = useStyles()

  useEffect(() => {}, [])

  return (
    <div>
      <NavBar />
      <div className={classes.navBarEmptySpaceBehind} />

      <div className={classes.body}>
        <div className={classes.body1}>
          <Typography variant="h2" className={classes.title}>
            Software solutions
          </Typography>
          <br />
          <Typography variant="body1" className={classes.text}>
            Combining maritime know how and software expertise enable us to develop applications for the maritime sectors demanding
            environment. We can build software solutions in-house or lead development project according to customer needs and assist in
            choosing the right technology for the task.
          </Typography>

          <div className={classes.space_1} />

          <img src={Build_img} alt="" className={classes.img} />

          <div className={classes.space_1} />

          <Typography variant="h3" className={classes.title}>
            Live Conning display
          </Typography>
          <br />
          <Typography variant="body1" className={classes.text}>
            As a part of a project, we developed a conning display that can be connected to an integrated bridge system. To get some fun
            insights to vessels daily operations we connected it up to ab public AIS data source allowing us to display ships conning
            display that are in Finnish waters.
          </Typography>

          <br />
        </div>
      </div>
      <Grid container className={classes.gridContainer}>
        {/* Conning DISPLAY */}
        
        <ConningDashboard />
        {/* Marine Traffic */}
        {/* <Grid item xs={12} md={5}>
          <iframe
          className={classes.marineTraffic}
            name="marinetraffic"
            id="marinetraffic"
            width="100%"
            height="100%"
            scrolling="no"
            frameBorder="0"
            src={
              "https://www.marinetraffic.com/en/ais/embed/zoom:10/centery:37.446/centerx:24.9467/maptype:1/shownames:false/mmsi:" +
              context.vesselMMSI +
              "/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:true/remember:false"
            }
          />
        </Grid> */}
      </Grid>

   
    </div>
  )
}

export default SoftwareSolutions
