/*
    Setting routs / pages
 */

// React
import React from "react"
import { Route, Router, Switch } from "react-router-dom"
import GlobalState from "./context/GlobalState"
import history from "./history"
import "./app.css"


import CssBaseline from "@material-ui/core/CssBaseline"
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes"
import { ThemeProvider } from "@material-ui/styles"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import ROUTES from "./ROUTES"
import BASE from "./AppWebpage/Base/BASE"
// PAGES
import PageHome from "./AppWebpage/PageHome/Home"
import PageAboutUs from "./AppWebpage/PageAboutUs/AboutUs"
import PageServices from "./AppWebpage/PageServices/Services"
import PageServicesDataDriveInsights from "./AppWebpage/PageServices/SubPages/DataDrivenInsights"
import PageServicesSoftwareSolutions from "./AppWebpage/PageServices/SubPages/SoftwareSolutions"
import PagesServicesProcessAutomation from "./AppWebpage/PageServices/SubPages/ProcessAutomation"
import PageServicesVirtualTours from "./AppWebpage/PageServices/SubPages/VirtualTours"
import PageNews from "./AppWebpage/PageNews/News"
import PageNewsArticle from "./AppWebpage/PageNews/Components/Article"
import PageContact from "./AppWebpage/PageContact/Contact"

import ConningDashboard from "./AppConnigDisplay/ConningDashboard"

// Global styling
const theme = createMuiTheme({
  palette: {
    type: "light", // light or dark theme
    primary: {
      light: "rgba(0,31,76,0.6)",
      main: "#001f4c",
      white: "#fff",
      red: "#ffc9d5",
    },
    secondary: {
      main: "#43ce4a",
      dark: "#2d8b32",
      red: "#ff354e",
    },
    background: {
      default: "##D6D6D6",
    },
  },
  overrides: {
    MuiTabs: { root: {} },
    MuiButton: {
      root: {
        backgroundColor: "#0F4699",
        borderRadius: "0.3rem",
        "&:hover": {
          backgroundColor: "#0A2F66",
        },
      },
      text: {
        color: "#fff", // Some CSS
      },
    },
    MuiTypography: {},
  },
  typography: {
    useNextVariants: true,
  },
})

const theme2 = responsiveFontSizes(theme)

function App() {

  return (
    <GlobalState>
      <CssBaseline />
      <ThemeProvider theme={theme2}>
        <Router history={history}>
          <BASE>
            <Switch>
              {/* Public */}
              <Route exact path={ROUTES.HOME} component={PageHome} />

              <Route exact path={ROUTES.ABOUT} component={PageAboutUs} />

              <Route exact path={ROUTES.SERVICES} component={PageServices} />
              <Route exact path={ROUTES.SERVICE_DATA} component={PageServicesDataDriveInsights} />
              <Route exact path={ROUTES.SERVICE_PROCESS_AUTOMATION} component={PagesServicesProcessAutomation} />
              <Route exact path={ROUTES.SERVICE_SOFTWARE_SOLUTIONS} component={PageServicesSoftwareSolutions} />
              <Route exact path={ROUTES.SERVICE_VIRTUAL_TOURS} component={PageServicesVirtualTours} />

              <Route exact path={ROUTES.NEWS} component={PageNews} />
              <Route exact path={ROUTES.NEWS_ARTICLE} component={PageNewsArticle} />

              <Route exact path={ROUTES.CONTACT} component={PageContact} />

              <Route exact path={ROUTES.CONNING_DISPLAY} component={ConningDashboard} />
            </Switch>
          </BASE>
        </Router>
      </ThemeProvider>
    </GlobalState>
  )
}

export default App
