/*
    Marketing Hub site element
 */

import React from "react"
import { Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "0.5em",
  },
  button: {
    margin: "1em 0em",
    padding: "1em 2em",
  },
  header: {
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    textAlign: "center",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-3rem",
    paddingBottom: "6rem",
    transform: "skewY(3deg)",
    background: theme.palette.primary.light,
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "9em",
    transform: "skewY(-3deg)",
    color: "#fff",
  },
  textBox2: {
    width: "90vw",
    maxWidth: "900px",
    marginBottom: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    width: "80%",
    maxHeight: "60vh",
    borderRadius: "0.5rem",
    objectFit: "cover",
  },
  iframe: {
    marginTop: "0.5em",
    width: "90vw",
    height: "80vh",
    maxHeight: "50rem",
    maxWidth: "1100px",
    // borderColor: '#000'
    borderColor: theme.palette.primary.main,
    borderWidth: "0px",
    borderRadius: "0.5rem",
  },
}))

const VRtour = () => {
  const classes = useStyles()

  return (
    <div className={classes.body}>
      <div className={classes.body2}>
        <div className={classes.textBox2}>
          <Typography variant="h2">Virtual ship tours</Typography>
          <br />
          <Typography variant="body1" className={classes.text}>
            Virtual environments are created from extremely high-quality 360 degrees photograph taken from real ships' environments and then
            adding on interactivity in our models with e.g. PDF:s, videos, images, sounds, text boxes and more, just the imagination puts
            the limits.
          </Typography>
        </div>
        <br />
        <iframe title="VR tour" src="https://maristo-360-tour-demo.firebaseapp.com/" className={classes.iframe} />
        <br />
        <Button className={classes.button} href={"https://hub.mari-sto.fi/"}>
          <Typography variant="button">Go to Hub Maristo</Typography>
        </Button>
      </div>
    </div>
  )
} // Class END

export default React.memo(VRtour)
