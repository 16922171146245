/*
    Side Menu List
 */

import React, { useContext } from "react"
import { Link } from "react-router-dom"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ContactIcon from "@material-ui/icons/PermPhoneMsgRounded"
import BoatIcon from "@material-ui/icons/DirectionsBoatRounded"
import HomeIcon from "@material-ui/icons/HomeRounded"
import CloseIcon from "@material-ui/icons/CloseRounded"
import ProjectIcon from "@material-ui/icons/BuildRounded"
import BlogIcon from "@material-ui/icons/ArtTrackRounded"
import makeStyles from "@material-ui/core/styles/makeStyles"
import contextMY from "../../../../context/MyContext"
import ROUTES from "../../../../ROUTES"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import LabelRounded from "@material-ui/icons/LabelRounded"
import Collapse from "@material-ui/core/Collapse"

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "24px",
  },
  itemClose: {
    backgroundColor: theme.palette.primary.red,
    "&:hover": {
      backgroundColor: theme.palette.secondary.red,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const MenuList = (props) => {
  const classes = useStyles()
  const context = useContext(contextMY)
  const [open, setOpen] = React.useState(false)

  function handleClick() {
    setOpen(!open)
  }

  return (
    <List>
      <ListItem button className={classes.itemClose} onClick={props.buttonClose}>
        <ListItemIcon>
          <CloseIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={"Close"} />
      </ListItem>
      <Divider />
      <ListItem button component={Link} to={ROUTES.HOME} selected={context.tabIndex === 0} onClick={props.buttonClose}>
        <ListItemIcon>
          <HomeIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={"Home"} />
      </ListItem>

      <ListItem button component={Link} to={ROUTES.ABOUT} selected={context.tabIndex === 1} onClick={props.buttonClose}>
        <ListItemIcon>
          <BoatIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={"About us"} />
      </ListItem>

      <ListItem button onClick={handleClick} selected={context.tabIndex === 2}>
        <ListItemIcon>
          <ProjectIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={"Services"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            to={ROUTES.SERVICES}
            className={classes.nested}
            selected={context.indexServices === "summary"}
            onClick={props.buttonClose}
          >
            <ListItemIcon>
              <LabelRounded />
            </ListItemIcon>
            <ListItemText primary={"Summary"} />
          </ListItem>

          <ListItem
            button
            component={Link}
            to={ROUTES.SERVICE_DATA}
            className={classes.nested}
            selected={context.indexServices === "data"}
            onClick={props.buttonClose}
          >
            <ListItemIcon>
              <LabelRounded />
            </ListItemIcon>
            <ListItemText primary={"Data Driven Insights"} />
          </ListItem>

          <ListItem
            button
            component={Link}
            to={ROUTES.SERVICE_PROCESS_AUTOMATION}
            className={classes.nested}
            selected={context.indexServices === "automation"}
            onClick={props.buttonClose}
          >
            <ListItemIcon>
              <LabelRounded />
            </ListItemIcon>
            <ListItemText primary={"Process Automation"} />
          </ListItem>

          <ListItem
            button
            component={Link}
            to={ROUTES.SERVICE_SOFTWARE_SOLUTIONS}
            className={classes.nested}
            selected={context.indexServices === "software"}
            onClick={props.buttonClose}
          >
            <ListItemIcon>
              <LabelRounded />
            </ListItemIcon>
            <ListItemText primary={"Software solutions"} onClick={props.buttonClose} />
          </ListItem>

          <ListItem
            button
            component={Link}
            to={ROUTES.SERVICE_VIRTUAL_TOURS}
            className={classes.nested}
            selected={context.indexServices === "tours"}
            onClick={props.buttonClose}
          >
            <ListItemIcon>
              <LabelRounded />
            </ListItemIcon>
            <ListItemText primary={"Virtual ship tours"} />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button component={Link} to={ROUTES.NEWS} selected={context.tabIndex === 3} onClick={props.buttonClose}>
        <ListItemIcon>
          <BlogIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={"News"} />
      </ListItem>

      <ListItem button component={Link} to={ROUTES.CONTACT} selected={context.tabIndex === 4} onClick={props.buttonClose}>
        <ListItemIcon>
          <ContactIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={"Contact"} />
      </ListItem>
      <Divider />
    </List>
  )
}

export default React.memo(MenuList)
