/*
    Services 

 */

// React
import React from "react"
import ROUTS from "../../../ROUTES"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
// Components
import Card from "../../../Components/Card_Pic_Title_Button.jsx"
// Pictures
import img_1 from "../../../Resources/Icone/DataAnalysis.png"
import img_3 from "../../../Resources/Icone/VirtualTour.png"
import img_4 from "../../../Resources/Icone/Automation.png"
import img_5 from "../../../Resources/Icone/Robot.svg"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    zIndex: "100",
  },
  gridContainer: {
    margin: "1em 0em",
  },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
  },
  title: {
    margin: "0.5em",
  },
  button: {
    margin: "1em 0em",
    padding: "0.5em 1em",
  },
  card: {
    height: "420px",
  },
  cardImg: {
    width: "25vw",
  },
}))

const Cards = () => {
  const classes = useStyles()

  return (
    <Grid container direction="row" justify="space-around" alignItems="center" alignContent="center" className={classes.gridContainer}>
      <Grid item xs={12} className={classes.center}>
        <Typography variant="h2" color="initial">
          Maristo provides
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card img={img_1} title={"Data Driven Insights"} linkTo={ROUTS.SERVICE_DATA} buttonText={"Read More"} />
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <Card img={img_5} title={"Process Automation"} linkTo={ROUTS.SERVICE_PROCESS_AUTOMATION} buttonText={"Read More"} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card img={img_4} title={"Software Solutions"} linkTo={ROUTS.SERVICE_SOFTWARE_SOLUTIONS} buttonText={"Read More"} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card img={img_3} title={"Virtual Ship Tours"} linkTo={ROUTS.SERVICE_VIRTUAL_TOURS} buttonText={"Read More"} />
      </Grid>
    </Grid>
  )
}

export default Cards
