/*
    Legal notice at bottom
 */

// React
import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.primary.white,
    textAlign: "center",
  },
  link: {
    color: theme.palette.primary.white,
  },
}))

const AboutUs = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.text}>
        {"Copyright ©  "}
        <Link href="https://mari-sto.fi/" className={classes.link}>
          mari-sto.fi
        </Link>
        {new Date().getFullYear()}
      </Typography>
    </div>
  )
}

export default React.memo(AboutUs)
