/*
    Component NAME
 */

import React, { useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import PicPivotLine from '../../Resources/PivotPoint/PivotLine.png'


const useStyles = makeStyles(theme => ({
  body: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
  },
  barPic: {
    position: 'absolute',
    height: '50vh',
    zIndex: '-10',
    paddingBottom: '1rem'
  },
  pivotBox: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '0.5rem',

  },
  pivotBoxText: {
    margin: '0px',
    padding: '0.2rem 1rem',
    textAlign: 'center'
  }
}));

const PivotPoint = (props) => {
  const classes = useStyles();

  const [pivot_point, set_pivot_point] = useState(0);
  const [loa, set_loa] = useState(100)
  const [pivot_vis, set_pivot_vis] = useState(35)

  useEffect(() => {
    set_pivot_vis(props.vis_dis)
    set_loa(props.loa)
    set_pivot_point(props.pivot_point_done)
  }, [props.pivot_point_done, props.vis_dis, props.loa])


  return (
    <div className={classes.body}>

      {/* Value element  */}
      <div className={classes.pivotBox} style={{marginBottom: pivot_vis + 'vh'}}>
        <h4 className={classes.pivotBoxText}>
          Pivot point
          <br/>
          {/*{(pivot_point - (loa / 2)).toFixed(0)} m*/}
          {(pivot_point - (loa/2)  ).toFixed(0)} m
        </h4>
      </div>

      {/* Bar line  */}
      <img className={classes.barPic} src={PicPivotLine} alt=""/>

    </div>
  );
};

export default PivotPoint