/*
    Contact Info
 */

import React, { useContext } from "react"
import contextMY from "../../context/MyContext"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Paper from "@material-ui/core/Paper"
import businessCard from "../../Resources/Visitkort_WEB.jpg"

const useStyles = makeStyles((theme) => ({
  navBarEmptySpaceBehind: {
    height: "52px",
  },
  footerEmptySpaceBehind: {
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "330px",
    },
  },
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    margin: "10%",
    maxWidth: "600px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: "99%",
    padding: "1%",
  },
}))

const AboutUs = () => {
  const context = useContext(contextMY)
  context.updateTab(4)
  context.updateIndexServices(0)
  // const [t] = useTranslation();
  const classes = useStyles()

  return (
    <div>
      <div className={classes.body}>
        <Paper className={classes.paper}>
          <img src={businessCard} alt="" className={classes.img} />
        </Paper>
      </div>
    </div>
  )
}

export default React.memo(AboutUs)
