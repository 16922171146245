/*
    About us page
 */

// React
import React, { useContext } from "react"
import contextMY from "../../context/MyContext"
import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"

import TextArea from "./Components/3_SubTitles"
import TeamMemberCard from "./Components/TeamMemberCard"

import IMG_ted from "../../Resources/Pictures/TeamMembers/Ted.jpg"
import img_mathias from "../../Resources/Pictures/TeamMembers/Mathias.jpg"
import img_mats from "../../Resources/Pictures/TeamMembers/Mats.jpg"
import img_nate from "../../Resources/Pictures/TeamMembers/Nate.jpg"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  navBarEmptySpaceBehind: { height: "52px" },
  footerEmptySpaceBehind: {
    height: "200px",
    [theme.breakpoints.down("xs")]: {
      height: "330px",
    },
  },
  headElement: {
    backgroundColor: theme.palette.primary.main,
    padding: "3em 5vw",
    textAlign: "center",
    width: "100vw",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    margin: "1em 1em",
  },
  gridContainer: {
    width: "95vw",
    marginBottom: "1em",
  },
}))

const AboutUs = () => {
  const context = useContext(contextMY)
  context.updateTab(1)
  context.updateIndexServices(0)
  const classes = useStyles()

  return (
    <div>

      <div className={classes.body}>
        <div className={classes.headElement}>
          <Typography variant={"h4"} color={"secondary"}>
            <b> Innovating in the maritime digital solution for you! </b>
          </Typography>
        </div>

        <TextArea />

        <Typography variant="h2" className={classes.title}>
          Maristo Team
        </Typography>

        <Grid container direction="row" justify="space-around" alignItems="stretch" spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={6} lg={3}>
            <TeamMemberCard
              profilePic={img_mathias}
              name={"Mathias Mattsson"}
              jobTitle={"CEO & Founder"}
              description={
                "Mathias is one of the founders. Mathias has a background from the maritime industry here he has a degree as a maritime captain from the Novia university and worked both at sea as an officer and now on the shore side as ship operator. Mathias is currently studying economics and information system at University of Åbo Akademi. In Maristo, Mathias actively engaged in business development and research projects."
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TeamMemberCard
              profilePic={IMG_ted}
              name={"Ted Sjöblom"}
              jobTitle={"COO & Founder"}
              description={
                "Ted is one of the founders of Maristo. Ted has a degree as a maritime captain from the Novia university and has a background as an officer in the maritime industry. Ted has been working as an officer at Eckerö Shipping, Royal Caribbean Cruise Line and Viking Line. Continued ashore as project specialist at Aboa Mare R&D department. Ted is currently studying economics and information system with minor in computer science at University of Åbo Akademi. In Maristo, Ted actively engaged in development and research projects."
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TeamMemberCard
              profilePic={img_mats}
              name={"Mats Granholm"}
              jobTitle={"CTO"}
              description={
                "Machine learning expert Mats joined the project in autumn 2018 and he has therefore also been included in this project since the beginning. Mats has been studying computer science at University of Lund where he has specialized in machine learning. Today Mats is working as a programmer for Cadmatic in Turku. In Maristo, Mats is working on web applications, optimisations and ML models."
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TeamMemberCard
              profilePic={img_nate}
              name={"Nataniel Jhutti “Nate”"}
              jobTitle={"CIO"}
              description={
                "Nate joined the project during the autumn 2018 and he has therefore also been included in the project since the beginning. Nate has been studying Computer Science and Information Systems at Åbo Akademi University. Nate is currently working at Staria as an RPA consultant. In Maristo, Nate is actively engaged in backend development, business processes and automation."
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default AboutUs
