/*
    News feed & upcoming events
 */

import React from 'react';
import CardNoLink from "../../../Components/Card_News_Pic_Title_Text_NoLink";
import Card from "../../../Components/Card_News_Pic_Title_Text";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Shift from "../../../Resources/Pictures/Shift.jpeg";
import MaritimeDay from "../../../Resources/Pictures/MaritimeDay.JPG";
import aboUnderliga from "../../../Resources/Pictures/Maristoduo1w.jpg";
import PicCyber from './../../../Resources/Pictures/News/Cyber.jpeg'


const useStyles = makeStyles(theme => ({
  navBarEmptySpaceBehind: {
    height: '52px'
  },
  footerEmptySpaceBehind: {
    height: '200px',
    [theme.breakpoints.down('xs')]: {
      height: '330px',
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    margin: '1em 0em',
    textAlign: 'center'
  }
}));

const AboutUs = props => {

  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant='h3' className={classes.title}> News feed </Typography>

      <Card img={PicCyber}
            title={'Cyber security course'}
            text={'First cybersecurity course in Finland organised cooperation between Maristo, Deductive labs and ' +
            'Aboa Mare. Course held at Aboa Mare facilities in Turku 10 February 2020. Course day included simulator ' +
            'exercises and theoretical lectures about cyber threats at sea. The course was fully booked and there were' +
            ' even people on the waiting list. It was a successful day at and Maristo is looking forward to the next course day.'}
            date={'2020-Feb-10'}
            linkTo={"https://svenska.yle.fi/artikel/2020/02/11/fartyg-ar-kansliga-for-cyberangrepp-expert-ju-mer-digitaliserade-fartygen-blir"}/>
      <hr/>

      <Card img={aboUnderliga}
            title={'Åbo underättelser - Maristo article'}
            text={'Big thank you to Åbo underättelser and Jan-Ole Edberg for telling the story about Maristo. ' +
            'Article named Maristo in Turku is riding the digital wave - startup company develops IT solutions for' +
            ' customers in shipping. You are able to read full article on Åbo Underrättesler web page.'}
            date={'2019-Oct-24'}
            linkTo={"https://news.abounderrattelser.fi/maristo-i-abo-rider-padigitaliseringsvagen-uppstartforetaget-utvecklar-it-losningar-for-kunder-inom-sjofarten/"}/>
      <hr/>

      <CardNoLink img={Shift}
                  title={'Maristo participated on SHIFT'}
                  text={'Maristo team participated in Shift. We was invited to round table discussion with Mayer Turku ' +
                  'about integrating corporate responsibility into supply chains. Big thank you to all we meet ' +
                  'on SHIFT and hope to see you soon again.'}
                  date={'2019-Aug-29'}
      />
      <hr/>

      <CardNoLink img={MaritimeDay}
                  title={'Maritime Day'}
                  text={'Maristo visited Aland islands to participate in Maritime Day. Demonstrated our first demo ' +
                  'application that allows user to scanning their certificate then certificate will be automatically ' +
                  'categoric certificate according to STCW code. We enjoyed the stay and happy to have such good ' +
                  'reception of a startup and giving us new possibilities.'}
                  date={'2019-May-08'}
      />
      <hr/>

    </React.Fragment>
  );
};

export default React.memo(AboutUs);