import React from 'react'

import Vessel from './Vessel/Vessel'
import HeadingDisplay from './HeadingDisplay/HeadingDisplay'
import RateOfTurnDisplay from './RateOfTurnDisplay/RateOfTurnDisplay'
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
    box: {
      maxWidth: "45rem",
   
    },
  }))
  

const ConningDisplay = ({ heading, rateOfTurn, bowSpeed, lateralSpeed, sternSpeed }) => {
    const classes = useStyles()
    return (
        <div className={classes.box}>
            <HeadingDisplay heading={heading}/>
            <RateOfTurnDisplay rateOfTurn={rateOfTurn}/>
            <Vessel bowSpeed={bowSpeed} lateralSpeed={lateralSpeed} sternSpeed={sternSpeed}/>
        </div>
    )
}

export default ConningDisplay