/*
    Navigation bar
 */

import React, { useContext } from "react"
import contextMY from "../../../context/MyContext"
import AppBar from "@material-ui/core/AppBar"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import LogoImg from "./Components/Logo"
import NavigationLinks from "./Components/NavigationLinks"
// import LanguageSelector from './Components/LanguageSelector'
import SideMenu from "./Components/SideMenu"
import DropDownManuServices from "./Components/DropDownManuServices"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.white,
    opacity: 0.98,
  },
  navLinkContainer: {
    // Mobile
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  icon: {
    marginLeft: "1.5em",
  },
  logoBox: {
    marginLeft: "1em",
  },
  iconBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "20px",
  },
}))

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const NavBar = (props) => {
  const classes = useStyles()
  const context = useContext(contextMY)

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar className={classes.appBar}>
          <Grid container className={classes.girdContainer} direction="row" justify="space-between" alignItems="center">
            <Grid item xs={2} className={classes.logoBox}>
              <LogoImg />
            </Grid>
            <Grid item sx={8} className={classes.navLinkContainer}>
              <NavigationLinks tabIndex={context.tabIndex} />
            </Grid>
            <Grid item xs={2} className={classes.iconBox}>
              <SideMenu />
            </Grid>
          </Grid>
          <DropDownManuServices />
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  )
}

export default NavBar
