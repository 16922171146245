/*
    Navigation links for navigation header
*/

import React, { useContext } from "react"
import history from "../../../../history"
import ROUTS from "../../../../ROUTES"
import contextMY from "../../../../context/MyContext"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  tabs: {
    height: "100%",
    minHeight: "4px",
    minWidth: "4px",
  },
  tab: {
    color: theme.palette.primary.light,
    borderRadius: "5px",
    textTransform: "none",
    height: "100%",
    minHeight: "4px",
    minWidth: "4px",
    width: "10vw",
    padding: "3px 0px",
    margin: "0px",

    button: {
      width: "20px",
    },
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "&:focus": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  dropMenu: {
    position: "absolute",
    zIndex: "100",
  },
}))

const NavLinks = (props) => {
  const classes = useStyles(useStyles)
  const context = useContext(contextMY)

  return (
    <div>
      <Tabs className={classes.tabs} value={context.tabIndex} variant="fullWidth">
        <Tab className={classes.tab} onClick={() => history.push(ROUTS.HOME)} label={"Home"} />
        <Tab
          className={classes.tab}
          onClick={() => history.push(ROUTS.ABOUT)}
          onMouseOver={context.closeDropDownServices}
          label={"About us"}
        />
        <Tab
          className={classes.tab}
          onClick={() => history.push(ROUTS.SERVICES)}
          aria-haspopup="true"
          onMouseOver={context.openDropDownServices}
          label={"Services"}
        />
        <Tab className={classes.tab} onClick={() => history.push(ROUTS.NEWS)} onMouseOver={context.closeDropDownServices} label={"News"} />
        <Tab className={classes.tab} onClick={() => history.push(ROUTS.CONTACT)} label={"Contact"} />
      </Tabs>
    </div>
  )
}

export default NavLinks
