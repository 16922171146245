/*
    Header element with text on the left side and IMG_HeadElement1 on right side.
    Responsive for Mobile and Web
 */

// React
import React, { Fragment } from "react"
// Material UI
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.secondary.main,
  },
  // Setting display for Mobile and Web
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  web: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  // Styling for Web
  gridItemLeft: {
    position: "relative",
    maxHeight: "19rem",
  },
  angled_box: {
    borderRightStyle: "solid",
    borderRight: "5px",
    color: theme.palette.primary.white,
    height: "100%",
    width: "100%",
    MsTransform: "matrix(1.2, 0, -0.4, 1, 0, 0)" /* IE 9 */,
    WebkitTransform: "matrix(1.2, 0, -0.4, 1, 0, 0)" /* Safari */,
    transform: "matrix(1.2, 0, -0.4, 1, 0, 0)",
    backgroundColor: theme.palette.primary.main,
  },
  textCenter: {
    position: "absolute",
    top: "0px",
    left: "1%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "105%",
    height: "100%",
  },
  text: {
    color: theme.palette.primary.white,
    textAlign: "center",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
    },
  },
  gridItemRight: {
    maxHeight: "19rem",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    zIndex: "1",
  },
  // Styling for Mobile
  textBoxMobil: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
  },
  textMobil: {
    paddingTop: "2%",
    paddingBottom: "3%",
    color: theme.palette.primary.white,
    textAlign: "center",
    fontSize: "2em",
  },
  imgBox: {
    backgroundColor: theme.palette.primary.main,
  },
  imgMobil: {
    width: "100%",
    maxHeight: "200px",
    objectFit: "cover",
  },
  lineSpace: {
    padding: "0.1em",
  },
}))

const HeaderTextPic = (props) => {
  const { image } = props
  const classes = useStyles()

  return (
    <Fragment>
      <div className={classes.web}>
        <Grid container direction="row" justify="center" alignItems="stretch" className={classes.paper}>
          <Grid item xs={7} className={classes.gridItemLeft}>
            <div className={classes.angled_box}></div>
            {/* Text component */}
            <div className={classes.textCenter}>
              <Typography variant="h1" className={classes.text}>
                <b>
                  <span className={classes.green}>Providing </span>
                  digital solutions
                  <div className={classes.lineSpace} />
                  for the
                  <span className={classes.green}> maritime sector </span>
                </b>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5} className={classes.gridItemRight}>
            <img src={image} alt="" className={classes.img} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.mobile}>
        <div className={classes.imgBox}>
          <img src={image} alt="" className={classes.imgMobil} />
        </div>
        <div className={classes.textBoxMobil}>
          <Typography variant="h1" className={classes.textMobil}>
            <b>
              <span className={classes.green}>Providing</span>
              digital solutions
              <div className={classes.lineSpace} />
              for the
              <span className={classes.green}> maritime sector </span>
            </b>
          </Typography>
        </div>
      </div>
    </Fragment>
  )
} // Class END

export default React.memo(HeaderTextPic)
