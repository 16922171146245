/*
    Card component
    - Picture
    - Title
    - Button
 */

// React
import React from 'react';
// Material UI
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles(theme => ({
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        justifyItems: 'flex-start',
        textDecoration: 'none',
        padding: '5px',
        color: '#000',
        '&:visited': {
            color: 'rgb(74,74,74)'
        },
    },
    textBox: {
        paddingLeft: '1vw',
        '&:hover': {
            color: '#000973'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '2em 1em',
            '&:hover': {
                color: '#000'
            },
        },
    },
    title: {
    },
    text:{
      marginTop: '0.5rem'
    },
    date_text: {},
    imgBox: {
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
    },
    img: {
        width: '95%',
        maxHeight: '30vh',
        objectFit: 'cover',
        borderRadius: '1em',
    },
    a:{
        textDecoration: 'none'
    }
}));


const Card = props => {

    const classes = useStyles();

    return (

        <a href={props.linkTo} className={classes.a}>
        <Grid container
              direction="row"
              justify="center"
              alignItems="flex-start"
              className={classes.gridContainer}
        >

            {/*      component={Link}*/}
            {/*      to={props.linkTo}>*/}

            <Grid item sm={12} md={4} className={classes.imgBox}>
                <img src={props.img} alt="" className={classes.img}/>
            </Grid>


            <Grid item sm={12} md={8} className={classes.textBox}>
                <Typography variant={"h5"} className={classes.title}>{props.title}</Typography>
                <Typography variant={"body1"} className={classes.text}>{props.text}</Typography>
                <br/>
                <Typography variant={"caption"} className={classes.date_text}>{props.date}</Typography>
            </Grid>

        </Grid>
        </a>
    );
}; // Class END


export default React.memo(Card);