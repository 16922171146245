/*
    Header element with picture left side and text on right side.
    Responsive for Mobile and Web
 */

// React
import React, {Fragment} from 'react';
// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({

    mobile: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    web: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    paper: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
    },
    gridItemLeft: {
        maxHeight: '19rem',
    },
    img: {
        height: '100%',
        width: '102%',
        objectFit: 'cover',
        zIndex: '1',
    },
    gridRight: {
        position: 'relative',
        zIndex: '10'
    },
    angled_box: {
        borderLeftStyle: 'solid',
        borderLeft: '6px',
        borderColor: '#fff',
        height: '100%',
        width: '98%',
        MsTransform: 'matrix(1, 0, -0.4, 1, -55, 0)', /* IE 9 */
        WebkitTransform: 'matrix(1, 0, -0.4, 1, -55, 0)', /* Safari */
        transform: 'matrix(1, 0, -0.4, 1, -55, 0)',
        backgroundColor: theme.palette.primary.main,
    },
    textCenter: {
        position: 'absolute',
        top: '0px',
        right: '5%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: "95%",
        height: '100%',
    },
    text: {
        color: theme.palette.primary.white,
        textAlign: 'center',
        fontSize: '2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem',
        },
    },
    // Styling for Mobile
    textBoxMobil: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    textMobil: {
        padding: '1em 0.5em',
        color: '#fff',
        textAlign: 'center',
        fontSize: '2em',
    },
    green: {
        color: '#47db4f'
    },
    imgBox: {
        backgroundColor: theme.palette.primary.main,
    },
    imgMobil: {
        width: '100%',
        maxHeight: '200px',
        objectFit: 'cover',
    },
    lineSpace:{
        padding: '0.1em'
    }
}));

const HeaderPicText = props => {

    const {image} = props;
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.web}>
                <Grid container direction='row'
                      justify='center'
                      alignItems='stretch'
                      className={classes.paper}>
                    <Grid item xs={5} className={classes.gridItemLeft}>
                        <img src={image} alt="" className={classes.img}/>
                    </Grid>
                    <Grid item xs={7} className={classes.gridRight}>
                        <div className={classes.angled_box}></div>
                        <div className={classes.textCenter}>
                            <Typography variant='h1' className={classes.text}>
                                <b> Building a
                                    <span className={classes.green}>{" bridge "}</span>
                                    <div className={classes.lineSpace}/>
                                    between
                                    <span className={classes.green}> {" IT "} </span>
                                    and
                                    <span className={classes.green}> {"maritime"} </span>
                                </b>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.mobile}>
                <div className={classes.imgBox}>
                    <img src={image} alt="" className={classes.imgMobil}/>
                </div>
                <div className={classes.textBoxMobil}>
                    <Typography variant='h1' className={classes.textMobil}>
                        <b>
                            Building a
                            <span className={classes.green}>{" bridge "}</span>
                            between
                            <div className={classes.lineSpace}/>
                            <span className={classes.green}> {" IT "} </span>
                            and
                            <span className={classes.green}> {"maritime"} </span>
                        </b>
                    </Typography>
                </div>
            </div>
        </Fragment>
    );
} // Class END

export default HeaderPicText;
