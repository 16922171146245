import React, { useContext } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { emphasize, makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
// import NoSsr from "@material-ui/core/NoSsr"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import Chip from "@material-ui/core/Chip"
import MenuItem from "@material-ui/core/MenuItem"
import CancelIcon from "@material-ui/icons/Cancel"
import contextMY from "../../context/MyContext"
import Autocomplete from "@material-ui/lab/Autocomplete"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 290,
    zIndex: 200,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === "light" ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}))

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
}

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  )
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any.isRequired,
  selectProps: PropTypes.object.isRequired,
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
  selectProps: PropTypes.object.isRequired,
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
}

export default function IntegrationReactSelect(props) {
  const classes = useStyles()
  const context = useContext(contextMY)

  const vesselList = [
    {
      label: "Aava VG ",
      mmsi: 212308000,
    },
    {
      label: "Adele ",
      mmsi: 275489000,
    },
    {
      label: "Ahto",
      mmsi: 230024000,
    },
    {
      label: "Aila",
      mmsi: 230985000,
    },
    {
      label: "Airisto ",
      mmsi: 230371000,
    },
    {
      label: "Amorella",
      mmsi: 230172000,
    },
    {
      label: "Antonia ",
      mmsi: 230682000,
    },
    {
      label: "Arkadia",
      mmsi: 230624000,
    },
    {
      label: "Aura",
      mmsi: 230601000,
    },
    {
      label: "Baltic Carrier",
      mmsi: 236038000,
    },
    {
      label: "Baltic Merchant",
      mmsi: 236195000,
    },
    {
      label: "Baltic Princess",
      mmsi: 230639000,
    },
    {
      label: "Baltic Sailor ",
      mmsi: 236196000,
    },
    {
      label: "Baltic Skipper",
      mmsi: 236039000,
    },
    {
      label: "Baltica ",
      mmsi: 230659000,
    },
    {
      label: "Barbarossa ",
      mmsi: 236029000,
    },
    {
      label: "Eeva VG ",
      mmsi: 230663000,
    },
    {
      label: "Eira",
      mmsi: 230935000,
    },
    {
      label: "Enough Talk ",
      mmsi: 230138350,
    },
    {
      label: "Fennica",
      mmsi: 230245000,
    },
    {
      label: "Finlandia",
      mmsi: 230628000,
    },
    {
      label: "Finnbreeze",
      mmsi: 230617000,
    },
    {
      label: "Finnfellow",
      mmsi: 230637000,
    },
    {
      label: "Finnhawk",
      mmsi: 230007000,
    },
    {
      label: "Finnkraft",
      mmsi: 230006000,
    },
    {
      label: "Finnlady",
      mmsi: 230987000,
    },
    {
      label: "Finnmaid",
      mmsi: 230982000,
    },
    {
      label: "Finnmaster",
      mmsi: 230368000,
    },
    {
      label: "Finnmerchant",
      mmsi: 230650000,
    },
    {
      label: "Finnmill",
      mmsi: 230642000,
    },
    {
      label: "Finnpartner",
      mmsi: 266262000,
    },
    {
      label: "Finnpulp",
      mmsi: 230643000,
    },
    {
      label: "Finnsky",
      mmsi: 230622000,
    },
    {
      label: "Finnstar",
      mmsi: 230981000,
    },
    {
      label: "Finnsun",
      mmsi: 230623000,
    },
    {
      label: "Finnswan",
      mmsi: 230671000,
    },
    {
      label: "Finntrader",
      mmsi: 266239000,
    },
    {
      label: "Finnwave",
      mmsi: 230632000,
    },
    {
      label: "Fjärdvägen",
      mmsi: 230341000,
    },
    {
      label: "Flex Keston",
      mmsi: 305188000,
    },
    {
      label: "Friendland ",
      mmsi: 236731000,
    },
    {
      label: "Galaxy",
      mmsi: 266301000,
    },
    {
      label: "Haaga",
      mmsi: 230683000,
    },
    {
      label: "Hjördis",
      mmsi: 230351000,
    },
    {
      label: "Jupiter",
      mmsi: 276776000,
    },
    {
      label: "Kallio",
      mmsi: 230645000,
    },
    {
      label: "Kontio",
      mmsi: 230251000,
    },
    {
      label: "Kraft",
      mmsi: 230276000,
    },
    {
      label: "Kumpula",
      mmsi: 230625000,
    },
    {
      label: "Laura",
      mmsi: 230350000,
    },
    {
      label: "Linda",
      mmsi: 230984000,
    },
    {
      label: "Link Star ",
      mmsi: 230191000,
    },
    {
      label: "Loraland ",
      mmsi: 236727000,
    },
    {
      label: "Lotta VG ",
      mmsi: 212369000,
    },
    {
      label: "Marc ",
      mmsi: 209010000,
    },
    {
      label: "Mariella",
      mmsi: 230181000,
    },
    {
      label: "Marjatta",
      mmsi: 230352000,
    },
    {
      label: "Mega Star",
      mmsi: 276829000,
    },
    {
      label: "Meri",
      mmsi: 230941700,
    },
    {
      label: "Mirva VG ",
      mmsi: 230662000,
    },
    {
      label: "Neptun",
      mmsi: 230279000,
    },
    {
      label: "Nordica",
      mmsi: 230275000,
    },
    {
      label: "Otso",
      mmsi: 230252000,
    },
    {
      label: "Pasila",
      mmsi: 230336000,
    },
    {
      label: "Polaris VG ",
      mmsi: 230651000,
    },
    {
      label: "Polaris",
      mmsi: 230661000,
    },
    {
      label: "Prima Ballerina",
      mmsi: 230630000,
    },
    {
      label: "Prima Celina",
      mmsi: 230005000,
    },
    {
      label: "Prima Donna",
      mmsi: 230015000,
    },
    {
      label: "Prima Queen",
      mmsi: 230673000,
    },
    {
      label: "Prima Viking",
      mmsi: 230011000,
    },
    {
      label: "Rosella",
      mmsi: 230180000,
    },
    {
      label: "Sailor",
      mmsi: 276817000,
    },
    {
      label: "Silja Europa ",
      mmsi: 276807000,
    },
    {
      label: "Silja Serenade ",
      mmsi: 230184000,
    },
    {
      label: "Silja Symphony ",
      mmsi: 265004000,
    },
    {
      label: "Sisu",
      mmsi: 230289000,
    },
    {
      label: "Störtebeker ",
      mmsi: 236119000,
    },
    {
      label: "Svante G",
      mmsi: 230046580,
    },
    {
      label: "Tali",
      mmsi: 230916000,
    },
    {
      label: "Thetis",
      mmsi: 8203141,
    },
    {
      label: "Tinntide",
      mmsi: 230631000,
    },
    {
      label: "Travetal ",
      mmsi: 210974000,
    },
    {
      label: "Urho",
      mmsi: 230290000,
    },
    {
      label: "Viikki",
      mmsi: 230670000,
    },
    {
      label: "Viking Grace",
      mmsi: 230629000,
    },
    {
      label: "Viking XPRS",
      mmsi: 276813000,
    },
    {
      label: "Voima",
      mmsi: 230291000,
    },
    {
      label: "Wasa Express ",
      mmsi: 230636000,
    },
    {
      label: "Zeus of Finland",
      mmsi: 212409000,
    },
  ]

  const [value, setValue] = React.useState({
    label: "Viking Grace",
    mmsi: 230629000,
  })

  return (
    <div className={classes.root}>
      <Autocomplete
        options={vesselList}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option) => option.label}
        style={{ width: 300 }}
        value={value}
        onChange={(event, newValue) => {
          console.log(newValue)
          if (newValue) {
            console.log("Updated MMSI")
            context.updateVesselMMSI(newValue.mmsi)
            context.updateWaitingOnAIS(true)
            props.setMMSI(newValue.mmsi)
          }
          setValue(newValue)
        }}
        renderInput={(params) => <TextField {...params} label="Select Vessel" variant="outlined" />}
      />
    </div>
  )
}
