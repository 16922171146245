/*
    Data Driven Insights
 */

import React, { useContext } from "react"
import contextMY from "../../../context/MyContext"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1em",
  },
  list: {
    marginTop: "1em",
  },
  textBox: {
    width: "90vw",
    maxWidth: "900px",
    marginBottom: "1em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  pic1: {
    height: "40vh",
    borderRadius: "1em",
    margin: "1em",
  },
  dashboard: {
    height: "62vw",
    maxHeight: "52rem",
    width: "90vw",
    maxWidth: "1200px",
    marginBottom: "2rem",
  },
}))

const DataAnalysis = (props) => {
  const context = useContext(contextMY)
  context.updateTab(2)
  context.updateIndexServices("data")

  const classes = useStyles()

  return (
    <div className={classes.body}>
      <div className={classes.textBox}>
        <Typography variant="h2">Data Driven Insight</Typography>
        <br />
        <Typography variant="body1">
          AI, machine learning, data analytics solutions can offer an immense competitive edge in the maritime industry. They enable a
          variety of new opportunities, mostly in improving efficiency in existing business processes, gain an new perspective and insights,
          monitor performance indicators and assist in data driven decision making.
          <br />
          creating customs dashboards that updating as data comes in or interactive reports for projects and research. At Maristo, we first
          identify our customer’s needs and thereafter we build a customized solution based on those needs and requirements.
          <br />
          At Maristo, we first with out charge assisting in exploitative ideation and identifying areas of implantation with our customer’s
          and there after coming up with an cost effective plan.
          <div className={classes.list}>
            In the maritime industry, it can for instance:
            <ul>
              <li>Analyze vessel performance from historical data</li>
              <li>Detect anomalies in operations</li>
              <li>Optimizing operation process </li>
              <li>Gain market insights</li>
              <li>Help in make informed decision on the latest information</li>
              <li>Share internally or externally insights</li>
            </ul>
          </div>
        </Typography>
      </div>

      <div className={classes.dashboard}>
        <iframe
        title='DashBoard'
          className={classes.frame}
          width="100%"
          height="100%"
          src="https://datastudio.google.com/embed/reporting/0c975920-545c-478b-b87e-1874c8de8a06/page/SbSkB"
          frameborder="0"
          style={{ border: "0", borderRadius: "0.3rem" }}
          allowfullscreen
        ></iframe>
      </div>
    </div>
  )
}

export default React.memo(DataAnalysis)
