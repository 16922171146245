/*
    Team member cards
 */

// React
import React from "react"
// Material UI
import { Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    width: "100%",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textArea: {},
  img: {
    width: "80%",
    marginBottom: "1rem",
    borderRadius: "0.5rem",
    objectFit: "cover",
    filter: "grayscale(100%)",
  },
  description: {
    textAlign: "justify",
    margin: "5%",
  },
}))

const TeamMemberCard = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <img src={props.profilePic} alt="" className={classes.img} />
      <Typography variant="h5">{props.name}</Typography>
      <Typography variant="subtitle1">{props.jobTitle}</Typography>
      <Typography variant="body1" className={classes.description}>
        {props.description}
      </Typography>
    </div>
  )
}

export default TeamMemberCard
