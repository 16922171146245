/*
    Text area component
 */

import React from "react"
// Material UI
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "1000px",
    padding: "20px",
    marginTop: "2%",
  },
  text: {
    margin: "2%",
  },
  title: {
    margin: "2%",
    marginBottom: "6%",
  },
}))

const ThreeSubTitles = (props) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Typography variant="h3" className={classes.title}>
        Get to know Maristo
      </Typography>
      <Typography variant="h6" className={classes.text}>
        Background to the Maristo
      </Typography>
      <Typography variant="body1" className={classes.text}>
        Maristo started in the summer of 2018, with building business relations and our first project taking it chape of developing a
        certification platform for mariners to help out our colleges to manage their paper mess. During the summer of 2019 we no longer
        worked on a single platform extended to work customer-oriented solutions and deployed a hub for virtual ship tours. In partnership
        with Deductive labs and Aboa Mare we organised the first-ever Cyber Security course with marinaras as a target group in Finland that
        included real cyber security simulation. From the start of 2020 we developing, building and implementing digital solutions with
        strong knowledge of the maritime field combined and technology expertise for the industry. With this combination, we can provide
        better custom made solutions for the industry that better with the needs of involved partners. If you have a plan for change,
        challenge or a vision please feel free to contact us to discuss potential solutions.
      </Typography>
      <Typography variant="h6" className={classes.text}>
        Our Values
      </Typography>
      <Typography variant="body1" className={classes.text}>
        <ul>
          <li>Customer-driven, prioritize client principles over profit</li>
          <li>Technology-focused, we explore and implement the latest in technological solutions </li>
          <li>Long-term success, innovate for creating an better tomorrow </li>
        </ul>
      </Typography>
      <Typography variant="h6" className={classes.text}>
        Who are we?
      </Typography>
      <Typography variant="body1" className={classes.text}>
        We are a energetic startup company from Turku, Finland. We are at the moment a four-person team with mixed skills from technology
        and Maritime. As a team we love problem-solving and new challenges to always grow our skill set. It is important for us to be
        leading expert in understand the limits and potentials of digital solutions so can provide and implement solutions of industry
        leading tools or in-house build custom expirnaaces. We are together combining our knowledge from these two worlds with a goal to
        digitalize the maritime industry increase efficiency.
      </Typography>
    </Paper>
  )
}

export default ThreeSubTitles
