/*
    Footer
 */

import React from "react"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"
import LegalBar from "./Components/LegalBar"
import Link from "@material-ui/core/Link"

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: "1rem",
    backgroundColor: theme.palette.primary.main,
    height: "15rem",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
  gridItem: {
    paddingTop: "10px",
    [theme.breakpoints.down("xs")]: {
      margin: "1rem 0rem",
    },
  },
  text: {
    color: theme.palette.primary.white,
    marginBottom: "5px",
    textAlign: "center",
  },
  title: {
    color: theme.palette.primary.white,
    marginBottom: "5px",
    textAlign: "center",
  },
  textBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    fill: theme.palette.primary.white,
    marginRight: "0.5rem",
  },
  socialLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  textLink: {
    marginLeft: "10px",
    color: theme.palette.primary.white,
    textDecoration: "none",
  },
  a: {
    textDecoration: "none",
  },
  link: {
    color: theme.palette.primary.white,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.white,
    },
    "&:visited": {
      color: theme.palette.primary.white,
    },
    "&:link": {
      color: theme.palette.primary.white,
    },
    "&:active": {
      color: theme.palette.primary.white,
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.gridContainer} direction="row" justify="space-around" alignItems="stretch">
      <Grid item xs={12} sm={4} className={classes.gridItem}>
        <Typography variant="h5" className={classes.title}>
          Quick Links
        </Typography>
        <br />
        <Typography variant="body1" className={classes.text}>
          <Link href={"https://hub.mari-sto.fi/"} className={classes.link}>
            Hub Maristo
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} className={classes.gridItem}>
        <Typography variant="h5" className={classes.title}>
          Contact us
        </Typography>
        <br />
        <Typography variant="body1" className={classes.text}>
          info@mari-sto.fi
          <br />
          +358 50 495 6942
          <br />
          <br />
          Turku, Finland
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4} className={classes.gridItem}>
        <Typography variant="h5" className={classes.title}>
          Follow us on
        </Typography>
        <br />
        <a href=" https://www.linkedin.com/company/maristo/" className={classes.a}>
          <div className={classes.socialLink}>
            <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
            <Typography variant="body1" className={classes.textLink}>
              LinkedIn
            </Typography>
          </div>
        </a>
        <a href="https://www.facebook.com/Maristo-763941080646720" className={classes.a}>
          <div className={classes.socialLink}>
            <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
            </svg>
            <Typography variant="body1" className={classes.textLink}>
              Facebook
            </Typography>
          </div>
        </a>

        <a href="https://www.instagram.com/maristo_company/" className={classes.a}>
          <div className={classes.socialLink}>
            <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
            <Typography variant="body1" className={classes.textLink}>
              Instagram
            </Typography>
          </div>
        </a>

        <a href="https://www.youtube.com/channel/UC8O3gNuDhqSgk6x70jbvTmA" className={classes.a}>
          <div className={classes.socialLink}>
            <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M4.652 0h1.44l.988 3.702.916-3.702h1.454l-1.665 5.505v3.757h-1.431v-3.757l-1.702-5.505zm6.594 2.373c-1.119 0-1.861.74-1.861 1.835v3.349c0 1.204.629 1.831 1.861 1.831 1.022 0 1.826-.683 1.826-1.831v-3.349c0-1.069-.797-1.835-1.826-1.835zm.531 5.127c0 .372-.19.646-.532.646-.351 0-.554-.287-.554-.646v-3.179c0-.374.172-.651.529-.651.39 0 .557.269.557.651v3.179zm4.729-5.07v5.186c-.155.194-.5.512-.747.512-.271 0-.338-.186-.338-.46v-5.238h-1.27v5.71c0 .675.206 1.22.887 1.22.384 0 .918-.2 1.468-.853v.754h1.27v-6.831h-1.27zm2.203 13.858c-.448 0-.541.315-.541.763v.659h1.069v-.66c.001-.44-.092-.762-.528-.762zm-4.703.04c-.084.043-.167.109-.25.198v4.055c.099.106.194.182.287.229.197.1.485.107.619-.067.07-.092.105-.241.105-.449v-3.359c0-.22-.043-.386-.129-.5-.147-.193-.42-.214-.632-.107zm4.827-5.195c-2.604-.177-11.066-.177-13.666 0-2.814.192-3.146 1.892-3.167 6.367.021 4.467.35 6.175 3.167 6.367 2.6.177 11.062.177 13.666 0 2.814-.192 3.146-1.893 3.167-6.367-.021-4.467-.35-6.175-3.167-6.367zm-12.324 10.686h-1.363v-7.54h-1.41v-1.28h4.182v1.28h-1.41v7.54zm4.846 0h-1.21v-.718c-.223.265-.455.467-.696.605-.652.374-1.547.365-1.547-.955v-5.438h1.209v4.988c0 .262.063.438.322.438.236 0 .564-.303.711-.487v-4.939h1.21v6.506zm4.657-1.348c0 .805-.301 1.431-1.106 1.431-.443 0-.812-.162-1.149-.583v.5h-1.221v-8.82h1.221v2.84c.273-.333.644-.608 1.076-.608.886 0 1.18.749 1.18 1.631v3.609zm4.471-1.752h-2.314v1.228c0 .488.042.91.528.91.511 0 .541-.344.541-.91v-.452h1.245v.489c0 1.253-.538 2.013-1.813 2.013-1.155 0-1.746-.842-1.746-2.013v-2.921c0-1.129.746-1.914 1.837-1.914 1.161 0 1.721.738 1.721 1.914v1.656z" />
            </svg>
            <Typography variant="body1" className={classes.textLink}>
              Youtube
            </Typography>
          </div>
        </a>
      </Grid>

      <Grid item xs={12}>
        <LegalBar />
      </Grid>
    </Grid>
  )
} // Class END

export default React.memo(Footer)
