/*
    Side Menu frame
    (component MenuList holds all data)
 */

// React
import React, { Fragment, useState } from "react"
import { withRouter } from "react-router-dom"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { compose } from "redux"
import IconButton from "@material-ui/core/IconButton"
import Drawer from "@material-ui/core/Drawer"
import MenuIcon from "@material-ui/icons/Menu"
import MyMenuList from "./MenuList"

const useStyles = makeStyles((theme) => ({
  iconBox: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.white,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.white,
    },
    "&:focus": {
      fill: theme.palette.primary.main,
    },
  },
  icon: {
    marginLeft: "0.2em",
    fontSize: "1.8rem",
    fill: theme.palette.primary.light,
    "&:hover": {
      fill: theme.palette.primary.main,
      opacity: 1,
    },
    "&:focus": {
      fill: theme.palette.primary.main,
    },
  },
  list: {
    width: "250px",
    // Mobile
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      textAlign: "center",
    },
  },
}))

const PrimarySearchAppBar = () => {
  const classes = useStyles()

  const [maneIsOpen, setManeIsOpen] = useState(false)

  const toggleMenu = () => {
    setManeIsOpen(!maneIsOpen)
  }

  return (
    <Fragment>
      <IconButton className={classes.iconBox} onClick={toggleMenu}>
        <MenuIcon className={classes.icon} />
      </IconButton>

      <Drawer anchor="right" open={maneIsOpen} onClose={toggleMenu}>
        <div className={classes.list}>
          <MyMenuList buttonClose={toggleMenu} />
        </div>
      </Drawer>
    </Fragment>
  )
}

export default compose(withRouter)(PrimarySearchAppBar)
