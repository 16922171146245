/*
    ROT indicator
 */

import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    rateOfTurn: {
        height: '4vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    rotBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '2vh',
        width: '12vw',
        maxWidth: '180px',
        minWidth: '65px',
        height: '4vh',
        backgroundColor: '#858b94',
        borderRadius: '0.5em',
    },
    progressBarPort: {
        position: 'relative',
        height: '1.5vh',
        width: '15vw',
        borderTopLeftRadius: '50em',
        borderBottomLeftRadius: '50em',
        border: '1px solid #485c6b',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    fillerPort: {
        background: '#a5041a',
        height: '100%',
        borderRadius: 'inherit',
        transition: 'width 0.2s ease-in'
    },
    fillerSB: {
        background: '#10a515',
        height: '100%',
        borderRadius: 'inherit',
        transition: 'width 0.2s ease-in'
    },
    progressBarSB: {
        position: 'relative',
        height: '1.5vh',
        width: '15vw',
        borderTopRightRadius: '50em',
        borderBottomRightRadius: '50em',
        border: '1px solid #485c6b',
    }

}));


const RateOfTurnDisplay = ({rateOfTurn}) => {
    const classes = useStyles();
    // Setting ROT to zero when going from SB to PS
    let rotP = 0;
    let rotS = rateOfTurn;
    if (rotS < 0) {
        rotP = Math.abs(rateOfTurn);
        rotS = 0
    }

    // TODO: These progress bars and rate of turn displays could probably be
    //       separate components.

    return (
        <div className={classes.rateOfTurn}>
            <div className={classes.progressBarPort}>
                <div className={classes.fillerPort} style={{width: `${rotP}%`}}/>
            </div>
            <div className={classes.rotBox}>
                <Typography variant={'h5'}>{rateOfTurn}°/min</Typography>
            </div>
            <div className={classes.progressBarSB}>
                <div className={classes.fillerSB} style={{width: `${rotS}%`}}/>
            </div>
        </div>
    )
};

export default RateOfTurnDisplay